import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, IconButton } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';

// Actions
import { milestoneCreateUpdate, milestoneDelete } from '../../store/actions';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Dialog from '../../components/Dialog/Dialog';

// Pages
import CreateUpdateMilestone from './CreateUpdateMilestone';

const ReadMilestone = (props) => {
  // On Load
  useEffect(() => {
    document.title = `Milestones | ${process.env.REACT_APP_NAME}`;
  }, []);

  // Local
  const [
    openCreateUpdateMilestone,
    setOpenCreateUpdateMilestone,
  ] = React.useState(false);
  const [valueForm, setValueForm] = React.useState(null);

  const handleOpenCreateMilestone = () => {
    setOpenCreateUpdateMilestone(true);
  };

  const handleOpenEditMilestone = (values) => {
    setValueForm(values);
    setOpenCreateUpdateMilestone(true);
  };

  const handleCloseCreateOrEditMilestone = () => {
    setValueForm(null);
    setOpenCreateUpdateMilestone(false);
  };

  const handleSubmitCreateUpdateMilestone = (values) => {
    const modifiedValues = {
      ...values,
      workspaceId: parseInt(props.currentWorkspace.id),
    };
    if (values.id) {
      props
        .onSubmitMilestone({
          url: '/milestone/update',
          values: {
            token: props.token,
            formData: modifiedValues,
          },
        })
        .then(() => {
          handleCloseCreateOrEditMilestone();
        });
    } else {
      props
        .onSubmitMilestone({
          url: '/milestone/create',
          values: {
            token: props.token,
            formData: modifiedValues,
          },
        })
        .then(() => {
          handleCloseCreateOrEditMilestone();
        });
    }
  };

  const handleSubmitDeleteMilestone = (id, name) => {
    const modifiedValues = {
      token: props.token,
      formData: { id: id },
    };
    const result = window.confirm(
      `Are you sure you want to delete milestone ${name}?`,
    );
    if (result) {
      props.onSubmitDeleteMilestone(modifiedValues);
    }
  };

  // Rows
  let tableStructure = [];
  if (props.milestones) {
    tableStructure = props.milestones.map((milestone) => {
      return [
        milestone.name,
        milestone.description ? milestone.description : 'Not Specified',
        milestone.startDate ? milestone.startDate : 'Not Specified',
        milestone.endDate ? milestone.endDate : 'Not Specified',
        props.currentWorkspaceRole.id !== 4 && {
          id: milestone.id,
          name: milestone.name,
          description: milestone.description,
          startDate: milestone.startDate
            ? milestone.startDate.split('T')[0]
            : null,
          endDate: milestone.endDate ? milestone.endDate.split('T')[0] : null,
        },
      ];
    });
  }

  // Columns
  const columns = ['Name', 'Description', 'Start Date', 'End Date'];

  if (props.currentWorkspaceRole.id !== 4)
    columns.push({
      name: 'Actions',
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                onClick={() => handleOpenEditMilestone(value)}
                color='primary'
                aria-label='create-edit-milestone'
                component='span'
                size='small'
              >
                <EditIcon fontSize='small' />
              </IconButton>
              <IconButton
                onClick={() =>
                  handleSubmitDeleteMilestone(value.id, value.name)
                }
                color='primary'
                aria-label='delete-milestone'
                component='span'
                size='small'
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
            </>
          );
        },
      },
    });

  // Config
  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
  };

  return (
    <>
      <PageTitle
        title={'Milestones'}
        button={
          props.currentWorkspaceRole.id !== 4 && (
            <Button
              variant='outlined'
              size='medium'
              color='secondary'
              onClick={handleOpenCreateMilestone}
            >
              Add Milestone
            </Button>
          )
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title='All Milestones'
            data={tableStructure}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={openCreateUpdateMilestone}
        handleClose={handleCloseCreateOrEditMilestone}
        title={`${valueForm ? 'Edit' : 'Create'} Milestone`}
        handleSubmit={handleSubmitCreateUpdateMilestone}
        isLoading={props.isLoading}
        initialValues={valueForm}
      >
        <CreateUpdateMilestone initialValues={valueForm} />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    milestones: state.milestone.milestones,
    isLoading: state.milestone.loading,
    currentWorkspace: state.global.currentWorkspace,
    currentWorkspaceRole: state.global.currentWorkspaceRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitMilestone: (values) => dispatch(milestoneCreateUpdate(values)),
    onSubmitDeleteMilestone: (values) => dispatch(milestoneDelete(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadMilestone);
