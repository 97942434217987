import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';

import App from './App';
import Themes from './themes';
import Store from './store/Store';
import { LayoutProvider } from './contexts/LayoutContext';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={Store}>
    <LayoutProvider>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </LayoutProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
