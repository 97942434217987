import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  ButtonGroup,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';

// Actions
import {
  ticketReadById,
  ticketCreateUpdate,
  ticketDelete,
  ticketCommentCreateUpdate,
  ticketCommentDelete,
} from '../../store/actions';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import Dialog from '../../components/Dialog/Dialog';

// Pages
import CreateUpdateTicket from './CreateUpdateTicket';
import AddComment from './AddComment';

// Styles
import useStyles from './ticketStyles';

function ViewTicket(props) {
  var classes = useStyles();

  // On Load
  useEffect(() => {
    props.onLoadCurrentTicket({
      token: props.token,
      ticketId: props.match.params.ticketId,
    });
    // eslint-disable-next-line
  }, []);

  // On Load/Update
  useEffect(() => {
    document.title = `Ticket #${
      props.currentTicket && props.currentTicket.displayId
        ? props.currentTicket.displayId
        : 0
    } | ${process.env.REACT_APP_NAME}`;
  }, [props.currentTicket]);

  const getDate = (date) => {
    var dateTime = new Date(date).toString();
    return dateTime;
  };

  // Local
  const [openEditTicket, setOpenEditTicket] = useState(false);
  const [openCreateEditComment, setOpenCreateEditComment] = useState(false);
  const [valueForm, setValueForm] = useState(null);

  const handleOpenEditTicket = (values) => {
    setValueForm({
      id: values.id,
      title: values.title,
      description: values.description,
      statusId: values.status ? values.status.id : null,
      priorityId: values.priority ? values.priority.id : null,
      milestoneId: values.milestone ? values.milestone.id : null,
      estimate: values.estimate,
      assignedToId: values.assignedTo ? values.assignedTo.id : null,
    });
    setOpenEditTicket(true);
  };

  const handleCloseEditTicket = () => {
    setValueForm(null);
    setOpenEditTicket(false);
  };

  const handleOpenCreateComment = () => {
    setValueForm(null);
    setOpenCreateEditComment(true);
  };

  const handleOpenEditComment = (values) => {
    setValueForm({
      id: values.id,
      comment: values.comment,
    });
    setOpenCreateEditComment(true);
  };

  const handleCloseCreateEditComment = () => {
    setValueForm(null);
    setOpenCreateEditComment(false);
  };

  const handleSubmitUpdateTicket = (values) => {
    const modifiedValues = {
      ...values,
      priorityId: values.priorityId ? values.priorityId : 3,
      statusId: values.statusId ? values.statusId : props.statuses[0].id,
      workspaceId: parseInt(props.currentWorkspace.id),
    };
    if (values.id) {
      props
        .onSubmitTicket({
          url: '/ticket/update',
          values: {
            token: props.token,
            formData: modifiedValues,
          },
        })
        .then(() => {
          handleCloseEditTicket();
        });
    }
  };

  const handleClickDeleteTicket = async (id, displayId) => {
    const modifiedValues = {
      token: props.token,
      formData: { id: id, workspaceId: parseInt(props.currentWorkspace.id) },
    };
    const result = window.confirm(
      `Are you sure you want to delete ticket #${displayId}?`,
    );
    if (result) {
      const result = await props.onSubmitDeleteTicket(modifiedValues);
      if (result) {
        props.history.goBack();
      }
    }
  };

  const handleSubmitCreateUpdateComment = async (values) => {
    const modifiedValues = {
      ...values,
      workspaceId: parseInt(props.currentWorkspace.id),
      ticketId: parseInt(props.currentTicket.id),
    };

    if (values.comment) {
      if (values.id) {
        const result = await props.onSubmitComment({
          url: '/ticket-comment/update',
          values: {
            token: props.token,
            formData: modifiedValues,
          },
        });
        if (result) {
          handleCloseCreateEditComment();
        }
      } else {
        const result = await props.onSubmitComment({
          url: '/ticket-comment/create',
          values: {
            token: props.token,
            formData: modifiedValues,
          },
        });
        if (result) {
          handleCloseCreateEditComment();
        }
      }
    }
  };

  const handleClickDeleteComment = (id) => {
    const modifiedValues = {
      token: props.token,
      formData: { id: id, workspaceId: parseInt(props.currentWorkspace.id) },
    };
    const result = window.confirm(
      `Are you sure you want to delete this comment?`,
    );
    if (result) {
      props.onSubmitDeleteComment(modifiedValues);
    }
  };

  // Helper
  const trimValues = (field, value) => {
    if (field === 'Description') {
      return `${value.replace(/(<([^>]+)>)/gi, '').slice(0, 19)}...`;
    } else {
      return value;
    }
  };

  // Map Users
  let assignedTo = {};
  if (props.teams) {
    assignedTo = props.teams.map((team) => {
      return { id: team.user.id, name: team.user.userName };
    });
  }

  return (
    <>
      <PageTitle
        title={`Ticket #${
          props.currentTicket && props.currentTicket.displayId
            ? props.currentTicket.displayId
            : 0
        }`}
        button={
          props.currentWorkspaceRole.id !== 4 && (
            <ButtonGroup color='secondary'>
              <Button onClick={() => handleOpenEditTicket(props.currentTicket)}>
                Edit
              </Button>
              <Button
                onClick={() =>
                  handleClickDeleteTicket(
                    props.currentTicket.id,
                    props.currentTicket.displayId,
                  )
                }
              >
                Delete
              </Button>
            </ButtonGroup>
          )
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Widget
            title={`${
              props.currentTicket && props.currentTicket.title
                ? props.currentTicket.title
                : 'Loading...'
            }`}
            disableWidgetMenu
          >
            <div
              className={classes.dashedBorder}
              dangerouslySetInnerHTML={{
                __html: props.currentTicket && props.currentTicket.description,
              }}
            ></div>
          </Widget>
        </Grid>
        <Grid item xs={12} md={4}>
          <Widget disableWidgetMenu>
            <div>
              <Grid container>
                <Grid item sm={4} className={classes.text}>
                  Created on
                </Grid>
                <Grid item sm={8} className={classes.text}>
                  {getDate(
                    props.currentTicket && props.currentTicket.createdOn,
                  )}
                </Grid>
                <Grid item sm={4} className={classes.text}>
                  Reported by
                </Grid>
                <Grid item sm={8} className={classes.text}>
                  {props.currentTicket.createdBy &&
                    props.currentTicket.createdBy.userName}
                </Grid>
                <Grid item sm={4} className={classes.text}>
                  Status
                </Grid>
                <Grid item sm={8} className={classes.text}>
                  {props.currentTicket.status
                    ? props.currentTicket.status.name
                    : '(Not Specified)'}
                </Grid>
                <Grid item sm={4} className={classes.text}>
                  Priority
                </Grid>
                <Grid item sm={8} className={classes.text}>
                  {props.currentTicket.priority
                    ? props.currentTicket.priority.name
                    : '(Not Specified)'}
                </Grid>
                <Grid item sm={4} className={classes.text}>
                  Assigned to
                </Grid>
                <Grid item sm={8} className={classes.text}>
                  {props.currentTicket.assignedTo
                    ? props.currentTicket.assignedTo.userName
                    : '(Not Specified)'}
                </Grid>
                <Grid item sm={4} className={classes.text}>
                  Milestone
                </Grid>
                <Grid item sm={8} className={classes.text}>
                  {props.currentTicket.milestone
                    ? props.currentTicket.milestone.name
                    : '(Not Specified)'}
                </Grid>
              </Grid>
            </div>
          </Widget>
        </Grid>
        <Grid item xs={12} md={8}>
          <Widget
            title='Comments'
            hasAddIcon
            handleAddIcon={() => handleOpenCreateComment()}
          >
            {props.currentTicket.comments &&
              props.currentTicket.comments.map((comment, index) => (
                <Grid
                  container
                  className={classes.userCommentsSection}
                  key={index}
                >
                  <Grid item xs={2} md={2}>
                    <Grid item xs={12} md={12} className={classes.boldFont}>
                      {comment.createdBy.userName}
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <IconButton
                        onClick={() => handleOpenEditComment(comment)}
                        color='secondary'
                        aria-label='edit-comment'
                        component='span'
                        size='small'
                        className={classes.iconButton}
                        disabled={props.user.id !== comment.createdBy.id}
                      >
                        <EditIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <IconButton
                        onClick={() => handleClickDeleteComment(comment.id)}
                        color='secondary'
                        aria-label='delete-comment'
                        component='span'
                        size='small'
                        className={classes.iconButton}
                        disabled={props.user.id !== comment.createdBy.id}
                      >
                        <DeleteIcon fontSize='small' />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    md={10}
                    className={classes.userDescriptionSection}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: comment.comment,
                      }}
                    ></div>
                  </Grid>
                </Grid>
              ))}
          </Widget>
        </Grid>
        <Grid item xs={12} md={4}>
          <Widget disableWidgetMenu>
            {props.currentTicket.changeLogs &&
              props.currentTicket.changeLogs.map((log) => (
                <Fragment key={log.id}>
                  <Grid item sm={12} className={classes.text}>
                    {`${log.createdBy.userName} : `}
                    <Typography className={classes.span}>
                      {`${log.fieldName} `}
                    </Typography>
                    {` ${log.action} `}
                    <Typography className={classes.span}>
                      {`${trimValues(log.fieldName, log.oldValue)}`}
                    </Typography>
                    {` ${'to'} `}
                    <Typography className={classes.span}>
                      {`${trimValues(log.fieldName, log.newValue)}`}
                    </Typography>
                  </Grid>
                </Fragment>
              ))}
          </Widget>
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={openEditTicket}
        handleClose={handleCloseEditTicket}
        title='Edit Ticket'
        handleSubmit={handleSubmitUpdateTicket}
        isLoading={props.isLoading}
        initialValues={valueForm}
        isCommentForm={true}
      >
        <CreateUpdateTicket
          statuses={props.statuses}
          assignedTo={assignedTo}
          token={props.token}
          priorities={props.priorities}
          milestones={props.milestones}
        />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={openCreateEditComment}
        handleClose={handleCloseCreateEditComment}
        title={`${valueForm ? 'Edit' : 'Create'} Comment`}
        handleSubmit={handleSubmitCreateUpdateComment}
        isLoading={props.isLoading}
        initialValues={valueForm}
      >
        <AddComment />
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    user: state.user.user,
    currentWorkspace: state.global.currentWorkspace,
    currentWorkspaceRole: state.global.currentWorkspaceRole,
    currentTicket: state.ticket.currentTicket,
    statuses: state.status.statuses,
    teams: state.team.teams,
    priorities: state.global.priorities,
    milestones: state.milestone.milestones,
    isLoading: state.ticket.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadCurrentTicket: (values) => dispatch(ticketReadById(values)),
    onSubmitTicket: (values) => dispatch(ticketCreateUpdate(values)),
    onSubmitDeleteTicket: (values) => dispatch(ticketDelete(values)),
    onSubmitComment: (values) => dispatch(ticketCommentCreateUpdate(values)),
    onSubmitDeleteComment: (values) => dispatch(ticketCommentDelete(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewTicket);
