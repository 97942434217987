import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities/helpers';

const initialState = {
  statuses: [],
  loading: false,
};

// Create or Update
const statusCreateUpdateRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const statusCreateUpdateSuccess = (state, action) => {
  let modifyStatusesForCreateOrUpdate = [...state.statuses];

  const statusIndex = modifyStatusesForCreateOrUpdate.findIndex(
    (status) => parseInt(action.payload.status.id) === parseInt(status.id),
  );

  if (statusIndex >= 0) {
    modifyStatusesForCreateOrUpdate.splice(
      statusIndex,
      1,
      action.payload.status,
    );
  } else {
    modifyStatusesForCreateOrUpdate = [
      ...state.statuses,
      action.payload.status,
    ];
  }

  return updateObject(state, {
    statuses: modifyStatusesForCreateOrUpdate,
    loading: false,
  });
};

const statusCreateUpdateFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Read
const statusReadRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const statusReadSuccess = (state, action) => {
  return updateObject(state, {
    statuses: action.payload.statuses,
    loading: false,
  });
};

const statusReadFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Delete
const statusDeleteRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const statusDeleteSuccess = (state, action) => {
  let modifyStatusesForDelete = [...state.statuses];

  const modifiedStatusesAfterDeleted = modifyStatusesForDelete.filter(
    (status) => parseInt(action.payload.statusId) !== parseInt(status.id),
  );

  return updateObject(state, {
    statuses: modifiedStatusesAfterDeleted,
    loading: false,
  });
};

const statusDeleteFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Reducer
const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    // Create or Update
    case actionTypes.STATUS_CREATE_UPDATE_REQUEST:
      return statusCreateUpdateRequest(state, action);
    case actionTypes.STATUS_CREATE_UPDATE_SUCCESS:
      return statusCreateUpdateSuccess(state, action);
    case actionTypes.STATUS_CREATE_UPDATE_FAILURE:
      return statusCreateUpdateFailure(state, action);
    // Read
    case actionTypes.STATUS_READ_REQUEST:
      return statusReadRequest(state, action);
    case actionTypes.STATUS_READ_SUCCESS:
      return statusReadSuccess(state, action);
    case actionTypes.STATUS_READ_FAILURE:
      return statusReadFailure(state, action);
    // Delete
    case actionTypes.STATUS_DELETE_REQUEST:
      return statusDeleteRequest(state, action);
    case actionTypes.STATUS_DELETE_SUCCESS:
      return statusDeleteSuccess(state, action);
    case actionTypes.STATUS_DELETE_FAILURE:
      return statusDeleteFailure(state, action);
    default:
      return state;
  }
};

export default statusReducer;
