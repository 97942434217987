import * as actionTypes from './actionTypes';
import { usePost, useGet } from '../utilities/webServices';

// Registration
const registrationRequest = () => {
  return {
    type: actionTypes.USER_REGISTRATION_REQUEST,
  };
};

const registrationSuccess = (values) => {
  return {
    type: actionTypes.USER_REGISTRATION_SUCCESS,
    payload: {
      message: values.message,
    },
  };
};

const registrationFailure = (values) => {
  return {
    type: actionTypes.USER_REGISTRATION_FAILURE,
    payload: { message: values.message },
  };
};

// Login
const loginRequest = () => {
  return {
    type: actionTypes.USER_LOGIN_REQUEST,
  };
};

const loginSuccess = (values) => {
  return {
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload: {
      token: values.token,
      user: values.user,
    },
  };
};

const loginFailure = (values) => {
  return {
    type: actionTypes.USER_LOGIN_FAILURE,
    payload: {
      message: values.message,
    },
  };
};

// Logout
const logoutUser = () => {
  return {
    type: actionTypes.USER_LOGOUT,
  };
};

// Timeout Message
export const timeoutMessage = () => {
  return {
    type: actionTypes.USER_ERROR_MESSAGE_TIMEOUT,
  };
};

// Verify Token
const verifyUserTokenRequest = () => {
  return {
    type: actionTypes.USER_LOGIN_TOKEN_VERIFICATION_REQUEST,
  };
};

const verifyUserTokenSuccess = (values) => {
  return {
    type: actionTypes.USER_LOGIN_TOKEN_VERIFICATION_SUCCESS,
    payload: {
      data: values.data,
      message: values.message,
    },
  };
};

const verifyUserTokenFailure = (values) => {
  return {
    type: actionTypes.USER_LOGIN_TOKEN_VERIFICATION_FAILURE,
    payload: {
      data: values.data ? true : false,
      message: values.message,
    },
  };
};

// Action Creators
// Register
export const userRegisteration = (values) => {
  return async (dispatch) => {
    let result = false;
    dispatch(registrationRequest());
    try {
      const response = await usePost('/register', values);
      if (response.data && response.data.userName) {
        result = true;
        dispatch(registrationSuccess(response));
      } else {
        dispatch(registrationFailure(response));
      }
    } catch (error) {
      dispatch(registrationFailure(error));
    }
    setTimeout(() => {
      dispatch(timeoutMessage());
    }, 5000);
    return result;
  };
};

// Login
export const userLogin = (values) => {
  return async (dispatch) => {
    dispatch(loginRequest());
    try {
      const response = await usePost('/authenticate', values);
      if (response.data && response.data.token) {
        let authData = {
          username: values.username,
          token: response.data.token,
          user: response.data.user,
        };
        localStorage.setItem('tmsAuth', JSON.stringify(authData));
        dispatch(loginSuccess(response.data));
      } else {
        dispatch(loginFailure(response));
        setTimeout(() => {
          dispatch(timeoutMessage());
        }, 5000);
      }
    } catch (error) {
      dispatch(loginFailure(error));
      setTimeout(() => {
        dispatch(timeoutMessage());
      }, 5000);
    }
  };
};

// Logout
export const userLogout = () => {
  return (dispatch) => {
    localStorage.removeItem('tmsAuth');
    dispatch(logoutUser());
    setTimeout(() => {
      dispatch(timeoutMessage());
    }, 5000);
  };
};

// Validate Token
export const userTokenValidation = (values) => {
  return async (dispatch) => {
    const response = await usePost('/validate', {
      token: values.token,
      formData: {},
    });
    if (response.status === 401 && response.error === 'Unauthorized') {
      dispatch(userLogout());
    }
  };
};

// Verify Token
export const userTokenVerification = (values) => {
  return async (dispatch) => {
    dispatch(verifyUserTokenRequest());
    try {
      const response = await useGet(values.url);
      if (response.data) {
        dispatch(verifyUserTokenSuccess(response));
      } else {
        dispatch(verifyUserTokenFailure(response));
      }
    } catch (error) {
      dispatch(verifyUserTokenFailure(error));
    }
  };
};
