import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities/helpers';

// Check localStorage for tmsSpacePref
let space_preference = localStorage.getItem('tmsSpacePref')
  ? JSON.parse(localStorage.getItem('tmsSpacePref'))
  : '';

const initialState = {
  currentWorkspace:
    space_preference && space_preference.currentWorkspace
      ? space_preference.currentWorkspace
      : {},
  currentWorkspaceUser:
    space_preference && space_preference.currentWorkspaceUser
      ? space_preference.currentWorkspaceUser
      : {},
  currentWorkspaceRole:
    space_preference && space_preference.currentWorkspaceRole
      ? space_preference.currentWorkspaceRole
      : {},
  roles: [],
  priorities: [],
  message: null,
  messageType: null, // 0 is Success, 1 is Error
  loading: false,
};

// Workspace Setter and Clearer
const workspaceSetterAndClearer = (state, action) => {
  return updateObject(state, {
    currentWorkspace: action.payload.currentWorkspace,
    currentWorkspaceUser: action.payload.currentWorkspaceUser,
    currentWorkspaceRole: action.payload.currentWorkspaceRole,
  });
};

// Message Setter and Clearer
const messageSetterAndClearer = (state, action) => {
  return updateObject(state, {
    message: action.payload.message,
    messageType: action.payload.messageType,
  });
};

// Loading Setter and Clearer
const loadingSetterAndClearer = (state, action) => {
  return updateObject(state, {
    loading: action.payload.loading,
  });
};

// Roles Fetcher
const rolesFetcher = (state, action) => {
  const modifyRolesForListing = [...action.payload.roles];
  const removeOwnerFromRoles = modifyRolesForListing.filter(
    (role) => parseInt(role.id) !== 1,
  );
  return updateObject(state, {
    roles: removeOwnerFromRoles,
    loading: false,
  });
};

// Priorities Fetcher
const prioritiesFetcher = (state, action) => {
  const modifyPrioritiesForListing = [...action.payload.priorities];

  return updateObject(state, {
    priorities: modifyPrioritiesForListing,
    loading: false,
  });
};

// Reducer
const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    // Workspace Setter and Clearer
    case actionTypes.GLOBAL_WORKSPACE_SETTER_AND_CLEARER:
      return workspaceSetterAndClearer(state, action);
    // Message Setter and Clearer
    case actionTypes.GLOBAL_MESSAGE_SETTER_AND_CLEARER:
      return messageSetterAndClearer(state, action);
    // Loading Setter and Clearer
    case actionTypes.GLOBAL_LOADING_SETTER_AND_CLEARER:
      return loadingSetterAndClearer(state, action);
    // Roles Fetcher
    case actionTypes.GLOBAL_ROLES_FETCHER:
      return rolesFetcher(state, action);
    // Priorities Fetcher
    case actionTypes.GLOBAL_PRIORITIES_FETCHER:
      return prioritiesFetcher(state, action);
    default:
      return state;
  }
};

export default globalReducer;
