import React, { useState } from 'react';
import {
  Paper,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { MoreVert as MoreIcon, Add as AddIcon } from '@material-ui/icons';
import classnames from 'classnames';

// Styles
import useStyles from './widgetStyles';

const Widget = (props) => {
  var classes = useStyles();

  // Local
  var [moreButtonRef, setMoreButtonRef] = useState(null);
  var [isMoreMenuOpen, setMoreMenuOpen] = useState(false);

  // Global
  const {
    children,
    title,
    noBodyPadding,
    bodyClass,
    disableWidgetMenu,
    header,
    noHeaderPadding,
    headerClass,
    style,
    noWidgetShadow,
    hasAddIcon,
    handleAddIcon,
  } = props;

  return (
    <div className={classes.widgetWrapper} style={style && { ...style }}>
      <Paper
        className={classes.paper}
        classes={{
          root: classnames(classes.widgetRoot, {
            [classes.noWidgetShadow]: noWidgetShadow,
          }),
        }}
      >
        <div
          className={classnames(classes.widgetHeader, {
            [classes.noPadding]: noHeaderPadding,
            [headerClass]: headerClass,
          })}
        >
          {header ? (
            header
          ) : (
            <>
              <Typography variant='h5' color='textSecondary' noWrap>
                {title}
              </Typography>
              {!disableWidgetMenu ? (
                !hasAddIcon ? (
                  <IconButton
                    color='primary'
                    classes={{ root: classes.moreButton }}
                    aria-owns='widget-menu'
                    aria-haspopup='true'
                    onClick={() => setMoreMenuOpen(true)}
                    buttonRef={setMoreButtonRef}
                  >
                    <MoreIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color='secondary'
                    aria-owns='add-menu'
                    aria-haspopup='true'
                    classes={{ root: classes.addButton }}
                    onClick={() => handleAddIcon()}
                  >
                    <AddIcon />
                  </IconButton>
                )
              ) : null}
            </>
          )}
        </div>
        <div
          className={classnames(classes.widgetBody, {
            [classes.noPadding]: noBodyPadding,
            [bodyClass]: bodyClass,
          })}
        >
          {children}
        </div>
      </Paper>
      <Menu
        id='widget-menu'
        open={isMoreMenuOpen}
        anchorEl={moreButtonRef}
        onClose={() => setMoreMenuOpen(false)}
        disableAutoFocusItem
      >
        <MenuItem>
          <Typography onClick={props.onClickOpen}>Open</Typography>
        </MenuItem>
        {props.showEdit && (
          <MenuItem>
            <Typography onClick={props.onClickEdit}>Edit</Typography>
          </MenuItem>
        )}
        {props.showDelete && (
          <MenuItem>
            <Typography onClick={props.onClickDelete}>Delete</Typography>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default Widget;
