import React from 'react';
import { Field } from 'react-final-form';

// Components
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';

const InviteUser = (props) => {
  return (
    <>
      <Field
        component={Input}
        id='toEmailid'
        name='toEmailid'
        label='Email Address'
        type='email'
        margin='normal'
        fullWidth
        required
        autoFocus
      />
      <Field
        component={Select}
        options={props.options}
        id='forRoleId'
        name='forRoleId'
        label='Role'
        margin='normal'
        hasEmptyOption={true}
        fullWidth
        required
      />
    </>
  );
};

export default InviteUser;
