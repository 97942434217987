import React from 'react';

// Components
import { Typography } from '../Wrappers/Wrappers';

// Styles
import useStyles from './pageTitleStyles';

const PageTitle = (props) => {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant='h1' size='sm'>
        {props.title}
      </Typography>
      {props.button && props.button}
    </div>
  );
};

export default PageTitle;
