import React, { useEffect } from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

// Components
import Layout from '../components/Layout/Layout';

// Pages
import Error from '../pages/Error/Error';
import VerifyLogin from '../pages/Login/VerifyLogin';
import Login from '../pages/Login/Login';

const Routes = ({ isAuthenticated, ...props }) => {
  // On Load
  useEffect(() => {
    if (props.token) {
      props.onLoadValidateToken({ token: props.token });
    }
    if (props.currentWorkspaceUser && props.user) {
      if (parseInt(props.currentWorkspaceUser.id) !== parseInt(props.user.id)) {
        props.onLoadClearWorkspace();
      }
    }
    // eslint-disable-next-line
  }, [props.token, props.user]);

  // Main Routes
  return (
    <HashRouter>
      <Switch>
        <PrivateRoute path='/dashboard' component={Layout} />
        <PrivateRoute
          path='/workspace/:workspaceId/ticket/:ticketId'
          component={Layout}
        />
        <PrivateRoute
          path='/workspace/:workspaceId/ticket'
          component={Layout}
        />
        <PrivateRoute
          path='/workspace/:workspaceId/milestone'
          component={Layout}
        />
        <PrivateRoute path='/workspace/:workspaceId/team' component={Layout} />
        {props.currentWorkspaceRole.id !== 4 && (
          <PrivateRoute
            path='/workspace/:workspaceId/status'
            component={Layout}
          />
        )}
        <PrivateRoute path='/workspace/:workspaceId' component={Layout} />
        <PublicRoute
          exact
          path='/user-verification/:token'
          component={VerifyLogin}
        />
        <PublicRoute exact path='/' component={Login} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );

  // Private Route
  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  // Public Route
  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: '/dashboard',
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
};

export default Routes;
