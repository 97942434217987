import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  InputBase,
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  ArrowBack as ArrowBackIcon,
  CheckCircle as CheckCircleIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

// Components
import { Badge, Typography } from '../Wrappers/Wrappers';

// Context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../contexts/LayoutContext';

// Styles
import useStyles from './headerStyles';

const Header = (props) => {
  var classes = useStyles();

  // Global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { onClickLogoutUser, search } = props;

  // Local
  var [profileMenu, setProfileMenu] = useState(null);
  var [notificationMenu, setNotificationMenu] = useState(null);
  var [isNotificationsRead, setIsNotificationsRead] = useState(true);
  var [isSearchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    if (search.length === 0) {
      setSearchOpen(false);
    }
  }, [search]);

  const submitAcceptInvite = async (id) => {
    const values = {
      token: props.token,
      formData: {
        id: id,
      },
    };
    const response = await props.onClickAcceptInvitation(values);
    if (response) props.onLoadWorkspaces({ token: props.token });
  };

  let acceptedCount = 0;
  if (props.invitations) {
    const acceptedInvitations = props.invitations.filter(
      (invitation) => invitation.accepted === false,
    );
    acceptedCount = acceptedInvitations.length;
  }

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color='inherit'
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant='h6' weight='medium' className={classes.logotype}>
          {process.env.REACT_APP_NAME}
        </Typography>
        <div className={classes.grow} />
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder='Search'
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            onKeyDown={props.handleSearch}
          />
        </div>
        <IconButton
          color='inherit'
          aria-haspopup='true'
          aria-controls='notifications-menu'
          onClick={(e) => {
            setNotificationMenu(e.currentTarget);
            setIsNotificationsRead(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsRead ? acceptedCount : null}
            color='secondary'
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        <IconButton
          aria-haspopup='true'
          color='inherit'
          className={classes.headerMenuButton}
          aria-controls='profile-menu'
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        <Menu
          id='notifications-menu'
          open={Boolean(notificationMenu)}
          anchorEl={notificationMenu}
          onClose={() => setNotificationMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant='h4' weight='medium'>
              Notifications
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component='a'
              color='secondary'
            >
              {acceptedCount} Workspace Requests
            </Typography>
          </div>
          {props.invitations.map((invitation) => {
            return !invitation.accepted ? (
              <MenuItem key={invitation.id} className={classes.notification}>
                <div className={classes.notificationSide}>
                  <CheckCircleIcon
                    className={classes.approveIcon}
                    onClick={() => submitAcceptInvite(invitation.id)}
                  />
                </div>
                <div
                  className={classNames(
                    classes.notificationSide,
                    classes.notificationBodySide,
                  )}
                >
                  <Typography weight='medium' gutterBottom>
                    {invitation.forWorkspace.name}
                  </Typography>
                  <Typography color='text' colorBrightness='secondary'>
                    {`${invitation.createdBy.firstName} has invited you to be ${invitation.forRole.name}.`}
                  </Typography>
                </div>
              </MenuItem>
            ) : null;
          })}
        </Menu>
        <Menu
          id='profile-menu'
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant='h4' weight='medium'>
              {`${props.user.firstName} ${props.user.lastName}`}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> My Account
          </MenuItem>
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color='primary'
              onClick={() => onClickLogoutUser()}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
