import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import classnames from 'classnames';

// Actions
import { userTokenVerification, timeoutMessage } from '../../store/actions';

// Logo
import logo from '../../assets/images/logo.png';

// Styles
import useStyles from './verifyLoginStyles';

const VerifyLogin = (props) => {
  var classes = useStyles();

  // On Load
  useEffect(() => {
    if (props.match.params.token) {
      props.onLoadVerifyUser({
        url: `verify-user?token=${props.match.params.token}`,
      });
    }
    // eslint-disable-next-line
  }, []);

  const onClickBackToLogin = () => {
    props.onClickClearMessage();
    props.history.replace('/');
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <img src={logo} alt='logo' className={classes.logotypeImage} />
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant='h1'
          color='primary'
          className={classnames(classes.textRow, classes.mainMessage)}
        >
          {props.isLoading ? (
            <CircularProgress color='inherit' />
          ) : props.messageType === 0 ? (
            'Yay!'
          ) : (
            'Oops!'
          )}
        </Typography>
        <Typography
          variant='h5'
          color={props.messageType === 0 ? 'primary' : 'secondary'}
          className={classes.textRow}
        >
          {!props.isLoading ? props.message : null}
        </Typography>
        {!props.isLoading ? (
          <Button
            variant='contained'
            color='primary'
            size='large'
            className={classes.backButton}
            onClick={onClickBackToLogin}
          >
            Back to Login
          </Button>
        ) : null}
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    message: state.user.message,
    messageType: state.user.messageType,
    isLoading: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVerifyUser: (values) => dispatch(userTokenVerification(values)),
    onClickClearMessage: () => dispatch(timeoutMessage()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyLogin);
