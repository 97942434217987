import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities/helpers';

const initialState = {
  milestones: [],
  loading: false,
};

// Create or Update
const milestoneCreateUpdateRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const milestoneCreateUpdateSuccess = (state, action) => {
  let modifyMilestonesForCreateOrUpdate = [...state.milestones];

  const milestoneIndex = modifyMilestonesForCreateOrUpdate.findIndex(
    (milestone) =>
      parseInt(action.payload.milestone.id) === parseInt(milestone.id),
  );

  if (milestoneIndex >= 0) {
    modifyMilestonesForCreateOrUpdate.splice(
      milestoneIndex,
      1,
      action.payload.milestone,
    );
  } else {
    modifyMilestonesForCreateOrUpdate = [
      ...state.milestones,
      action.payload.milestone,
    ];
  }

  return updateObject(state, {
    milestones: modifyMilestonesForCreateOrUpdate,
    loading: false,
  });
};

const milestoneCreateUpdateFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Read
const milestoneReadRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const milestoneReadSuccess = (state, action) => {
  return updateObject(state, {
    milestones: action.payload.milestones,
    loading: false,
  });
};

const milestoneReadFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Delete
const milestoneDeleteRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const milestoneDeleteSuccess = (state, action) => {
  let modifyMilestonesForDelete = [...state.milestones];

  const modifiedMilestonesAfterDeleted = modifyMilestonesForDelete.filter(
    (milestone) =>
      parseInt(action.payload.milestoneId) !== parseInt(milestone.id),
  );

  return updateObject(state, {
    milestones: modifiedMilestonesAfterDeleted,
    loading: false,
  });
};

const milestoneDeleteFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Reducer
const milestoneReducer = (state = initialState, action) => {
  switch (action.type) {
    // Create or Update
    case actionTypes.MILESTONE_CREATE_UPDATE_REQUEST:
      return milestoneCreateUpdateRequest(state, action);
    case actionTypes.MILESTONE_CREATE_UPDATE_SUCCESS:
      return milestoneCreateUpdateSuccess(state, action);
    case actionTypes.MILESTONE_CREATE_UPDATE_FAILURE:
      return milestoneCreateUpdateFailure(state, action);
    // Read
    case actionTypes.MILESTONE_READ_REQUEST:
      return milestoneReadRequest(state, action);
    case actionTypes.MILESTONE_READ_SUCCESS:
      return milestoneReadSuccess(state, action);
    case actionTypes.MILESTONE_READ_FAILURE:
      return milestoneReadFailure(state, action);
    // Delete
    case actionTypes.MILESTONE_DELETE_REQUEST:
      return milestoneDeleteRequest(state, action);
    case actionTypes.MILESTONE_DELETE_SUCCESS:
      return milestoneDeleteSuccess(state, action);
    case actionTypes.MILESTONE_DELETE_FAILURE:
      return milestoneDeleteFailure(state, action);
    default:
      return state;
  }
};

export default milestoneReducer;
