import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities/helpers';

const initialState = {
  workspaces: [],
  invitations: [],
  sentInvitations: [],
  loading: false,
};

// Create or Update
const workspaceCreateUpdateRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const workspaceCreateUpdateSuccess = (state, action) => {
  let modifyWorkspacesForCreateOrUpdate = [...state.workspaces];

  const workspaceIndex = modifyWorkspacesForCreateOrUpdate.findIndex(
    (workspace) =>
      parseInt(action.payload.workspace.id) === parseInt(workspace.id),
  );

  if (workspaceIndex >= 0) {
    modifyWorkspacesForCreateOrUpdate.splice(
      workspaceIndex,
      1,
      action.payload.workspace,
    );
  } else {
    modifyWorkspacesForCreateOrUpdate = [
      ...state.workspaces,
      action.payload.workspace,
    ];
  }

  return updateObject(state, {
    workspaces: modifyWorkspacesForCreateOrUpdate,
    loading: false,
  });
};

const workspaceCreateUpdateFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Read
const workspaceReadRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const workspaceReadSuccess = (state, action) => {
  return updateObject(state, {
    workspaces: action.payload.workspaces,
    loading: false,
  });
};

const workspaceReadFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Read Invitations
const workspaceInvitationReadRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const workspaceInvitationReadSuccess = (state, action) => {
  return updateObject(state, {
    invitations: action.payload.invitations,
    loading: false,
  });
};

const workspaceInvitationReadFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Accept Invitation
const workspaceInvitationAcceptRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const workspaceInvitationAcceptSuccess = (state, action) => {
  const modifyInvitationsForAcceptInvite = [...state.invitations];

  const hasInvitationIndex = modifyInvitationsForAcceptInvite.findIndex(
    (invitation) =>
      parseInt(invitation.id) === parseInt(action.payload.invitation.id),
  );

  if (hasInvitationIndex >= 0) {
    modifyInvitationsForAcceptInvite.splice(hasInvitationIndex, 1);
  }

  return updateObject(state, {
    invitations: modifyInvitationsForAcceptInvite,
    loading: false,
  });
};

const workspaceInvitationAcceptFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Read Sent Invitations
const workspaceSentInvitationReadRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const workspaceSentInvitationReadSuccess = (state, action) => {
  return updateObject(state, {
    sentInvitations: action.payload.sentInvitations,
    loading: false,
  });
};

const workspaceSentInvitationReadFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Delete
const workspaceDeleteRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const workspaceDeleteSuccess = (state, action) => {
  const modifyWorkspacesForDelete = [...state.workspaces];

  const removedWorkspaceForDelete = modifyWorkspacesForDelete.filter(
    (workspace) =>
      parseInt(workspace.workspace.id) !== parseInt(action.payload.workspaceId),
  );

  return updateObject(state, {
    workspaces: removedWorkspaceForDelete,
    loading: false,
  });
};

const workspaceDeleteFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Reducer
const workspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    // Create or Update
    case actionTypes.WORKSPACE_CREATE_UPDATE_REQUEST:
      return workspaceCreateUpdateRequest(state, action);
    case actionTypes.WORKSPACE_CREATE_UPDATE_SUCCESS:
      return workspaceCreateUpdateSuccess(state, action);
    case actionTypes.WORKSPACE_CREATE_UPDATE_FAILURE:
      return workspaceCreateUpdateFailure(state, action);
    // Read
    case actionTypes.WORKSPACE_READ_REQUEST:
      return workspaceReadRequest(state, action);
    case actionTypes.WORKSPACE_READ_SUCCESS:
      return workspaceReadSuccess(state, action);
    case actionTypes.WORKSPACE_READ_FAILURE:
      return workspaceReadFailure(state, action);
    // Read Invitations
    case actionTypes.WORKSPACE_INVITATION_READ_REQUEST:
      return workspaceInvitationReadRequest(state, action);
    case actionTypes.WORKSPACE_INVITATION_READ_SUCCESS:
      return workspaceInvitationReadSuccess(state, action);
    case actionTypes.WORKSPACE_INVITATION_READ_FAILURE:
      return workspaceInvitationReadFailure(state, action);
    // Accept Invitation
    case actionTypes.WORKSPACE_INVITATION_ACCEPT_REQUEST:
      return workspaceInvitationAcceptRequest(state, action);
    case actionTypes.WORKSPACE_INVITATION_ACCEPT_SUCCESS:
      return workspaceInvitationAcceptSuccess(state, action);
    case actionTypes.WORKSPACE_INVITATION_ACCEPT_FAILURE:
      return workspaceInvitationAcceptFailure(state, action);
    // Read Sent Invitations
    case actionTypes.WORKSPACE_SENT_INVITATION_READ_REQUEST:
      return workspaceSentInvitationReadRequest(state, action);
    case actionTypes.WORKSPACE_SENT_INVITATION_READ_SUCCESS:
      return workspaceSentInvitationReadSuccess(state, action);
    case actionTypes.WORKSPACE_SENT_INVITATION_READ_FAILURE:
      return workspaceSentInvitationReadFailure(state, action);
    // Delete
    case actionTypes.WORKSPACE_DELETE_REQUEST:
      return workspaceDeleteRequest(state, action);
    case actionTypes.WORKSPACE_DELETE_SUCCESS:
      return workspaceDeleteSuccess(state, action);
    case actionTypes.WORKSPACE_DELETE_FAILURE:
      return workspaceDeleteFailure(state, action);
    default:
      return state;
  }
};

export default workspaceReducer;
