import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  dashedBorder: {
    border: `1px dotted ${theme.palette.text.hint}`,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1),
    '& img': {
      width: '100%',
    },
  },
  text: {
    borderBottom: `1px dotted ${theme.palette.text.hint}`,
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  submitButton: {
    textTransform: 'none',
  },
  userCommentsSection: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
  },
  boldFont: {
    fontWeight: 'bold',
  },
  userDescriptionSection: {
    marginBottom: theme.spacing(1),
    border: `1px dotted ${theme.palette.text.hint}`,
    padding: theme.spacing(2),
  },
  iconButton: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: 0,
  },
  span: {
    display: 'inline-block',
    fontWeight: 'bold',
    fontSize: '14px',
  },
}));
