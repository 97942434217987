import * as actionTypes from './actionTypes';
import { usePost, useGet } from '../utilities/webServices';
import { userLogout } from './userAction';
import { messageSetterAndClearer } from './globalAction';

// Create or Update
const statusCreateUpdateRequest = () => {
  return {
    type: actionTypes.STATUS_CREATE_UPDATE_REQUEST,
  };
};

const statusCreateUpdateSuccess = (values) => {
  return {
    type: actionTypes.STATUS_CREATE_UPDATE_SUCCESS,
    payload: {
      status: values.data,
    },
  };
};

const statusCreateUpdateFailure = () => {
  return {
    type: actionTypes.STATUS_CREATE_UPDATE_FAILURE,
  };
};

// Read
const statusReadRequest = () => {
  return {
    type: actionTypes.STATUS_READ_REQUEST,
  };
};

const statusReadSuccess = (values) => {
  return {
    type: actionTypes.STATUS_READ_SUCCESS,
    payload: {
      statuses: values.data,
    },
  };
};

const statusReadFailure = () => {
  return {
    type: actionTypes.STATUS_READ_FAILURE,
  };
};

// Delete
const statusDeleteRequest = () => {
  return {
    type: actionTypes.STATUS_DELETE_REQUEST,
  };
};

const statusDeleteSuccess = (values) => {
  return {
    type: actionTypes.STATUS_DELETE_SUCCESS,
    payload: {
      statusId: values.id,
    },
  };
};

const statusDeleteFailure = () => {
  return {
    type: actionTypes.STATUS_DELETE_FAILURE,
  };
};

// Action Creators
// Create or Update
export const statusCreateUpdate = (values) => {
  return async (dispatch) => {
    dispatch(statusCreateUpdateRequest());
    try {
      const response = await usePost(values.url, values.values);
      if (response.data && response.data.id) {
        dispatch(statusCreateUpdateSuccess(response));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(statusCreateUpdateFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(statusCreateUpdateFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Read
export const statusRead = (values) => {
  return async (dispatch) => {
    dispatch(statusReadRequest());
    try {
      const response = await useGet(
        `/status/list-all/${values.workspaceId}`,
        values.token,
      );
      if (response && response.data) {
        dispatch(statusReadSuccess(response));
      } else if ((response.status = 401)) {
        dispatch(userLogout());
      } else {
        dispatch(statusReadFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(statusReadFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Delete
export const statusDelete = (values) => {
  return async (dispatch) => {
    dispatch(statusDeleteRequest());
    try {
      const response = await usePost('status/delete', values);
      if (response.data) {
        dispatch(statusDeleteSuccess(values.formData));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(statusDeleteFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(statusDeleteFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};
