import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities/helpers';

const initialState = {
  tickets: [],
  currentTicket: {},
  images: [],
  search: [],
  loading: false,
};

// Create or Update
const ticketCreateUpdateRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const ticketCreateUpdateSuccess = (state, action) => {
  let modifyTicketsForCreateOrUpdate = [...state.tickets];
  let modifyCurrentTicketForUpdate = { ...state.currentTicket };

  const ticketIndex = modifyTicketsForCreateOrUpdate.findIndex(
    (ticket) => parseInt(action.payload.ticket.id) === parseInt(ticket.id),
  );

  if (ticketIndex >= 0) {
    modifyTicketsForCreateOrUpdate.splice(
      ticketIndex,
      1,
      action.payload.ticket,
    );
    modifyCurrentTicketForUpdate = { ...action.payload.ticket };
  } else {
    modifyTicketsForCreateOrUpdate = [...state.tickets, action.payload.ticket];
  }

  return updateObject(state, {
    tickets: modifyTicketsForCreateOrUpdate,
    currentTicket: modifyCurrentTicketForUpdate,
    loading: false,
  });
};

const ticketCreateUpdateFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Read
const ticketReadRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const ticketReadSuccess = (state, action) => {
  return updateObject(state, {
    tickets: action.payload.tickets,
    loading: false,
  });
};

const ticketReadFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Read By ID
const ticketReadByIdRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const ticketReadByIdSuccess = (state, action) => {
  return updateObject(state, {
    currentTicket: action.payload.currentTicket,
    loading: false,
  });
};

const ticketReadByIdFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Delete
const ticketDeleteRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const ticketDeleteSuccess = (state, action) => {
  let modifyTicketsForDelete = [...state.tickets];

  const modifiedTicketsAfterDeleted = modifyTicketsForDelete.filter(
    (ticket) => parseInt(action.payload.ticketId) !== parseInt(ticket.id),
  );

  return updateObject(state, {
    tickets: modifiedTicketsAfterDeleted,
    currentTicket: {},
    loading: false,
  });
};

const ticketDeleteFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Create or Update Comment
const ticketCommentCreateUpdateRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const ticketCommentCreateUpdateSuccess = (state, action) => {
  let modifyTicketsForCommentCreateOrUpdate = [...state.tickets];
  let modifyCurrentTicketForCommentCreateOrUpdate = { ...state.currentTicket };

  const currentTicketIndex = modifyTicketsForCommentCreateOrUpdate.findIndex(
    (ticket) => parseInt(action.payload.ticket.id) === parseInt(ticket.id),
  );

  if (currentTicketIndex >= 0) {
    modifyTicketsForCommentCreateOrUpdate.splice(
      currentTicketIndex,
      1,
      action.payload.ticket,
    );
  }

  modifyCurrentTicketForCommentCreateOrUpdate = { ...action.payload.ticket };

  return updateObject(state, {
    tickets: modifyTicketsForCommentCreateOrUpdate,
    currentTicket: modifyCurrentTicketForCommentCreateOrUpdate,
    loading: false,
  });
};

const ticketCommentCreateUpdateFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Delete Comment
const ticketCommentDeleteRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const ticketCommentDeleteSuccess = (state, action) => {
  let modifyCurrentTicketForCommentDelete = { ...state.currentTicket };
  let modifyCurrentTicketCommentForCommentDelete = [
    ...state.currentTicket.comments,
  ];

  const modifiedTicketCommentAfterCommentDeleted = modifyCurrentTicketCommentForCommentDelete.filter(
    (comment) => parseInt(action.payload.commentId) !== parseInt(comment.id),
  );

  modifyCurrentTicketForCommentDelete = {
    ...state.currentTicket,
    comments: modifiedTicketCommentAfterCommentDeleted,
  };

  return updateObject(state, {
    currentTicket: modifyCurrentTicketForCommentDelete,
    loading: false,
  });
};

const ticketCommentDeleteFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Images Setter and Clearer
const ticketImagesSetterAndClearer = (state, action) => {
  let modifyImagesForSettingAndClearing = [...state.images];

  if (modifyImagesForSettingAndClearing.length > 0) {
    modifyImagesForSettingAndClearing = [
      ...state.images,
      action.payload.ticketId,
    ];
  } else {
    modifyImagesForSettingAndClearing = [action.payload.ticketId];
  }

  return updateObject(state, {
    images: modifyImagesForSettingAndClearing,
  });
};

// Search
const ticketSearchRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const ticketSearchSuccess = (state, action) => {
  return updateObject(state, {
    search: [...action.payload.search],
    loading: false,
  });
};

const ticketSearchFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Reducer
const ticketReducer = (state = initialState, action) => {
  switch (action.type) {
    // Create or Update
    case actionTypes.TICKET_CREATE_UPDATE_REQUEST:
      return ticketCreateUpdateRequest(state, action);
    case actionTypes.TICKET_CREATE_UPDATE_SUCCESS:
      return ticketCreateUpdateSuccess(state, action);
    case actionTypes.TICKET_CREATE_UPDATE_FAILURE:
      return ticketCreateUpdateFailure(state, action);
    // Read
    case actionTypes.TICKET_READ_REQUEST:
      return ticketReadRequest(state, action);
    case actionTypes.TICKET_READ_SUCCESS:
      return ticketReadSuccess(state, action);
    case actionTypes.TICKET_READ_FAILURE:
      return ticketReadFailure(state, action);
    // Read By ID
    case actionTypes.TICKET_READ_BY_ID_REQUEST:
      return ticketReadByIdRequest(state, action);
    case actionTypes.TICKET_READ_BY_ID_SUCCESS:
      return ticketReadByIdSuccess(state, action);
    case actionTypes.TICKET_READ_BY_ID_FAILURE:
      return ticketReadByIdFailure(state, action);
    // Delete
    case actionTypes.TICKET_DELETE_REQUEST:
      return ticketDeleteRequest(state, action);
    case actionTypes.TICKET_DELETE_SUCCESS:
      return ticketDeleteSuccess(state, action);
    case actionTypes.TICKET_DELETE_FAILURE:
      return ticketDeleteFailure(state, action);
    // Create or Update Comment
    case actionTypes.TICKET_COMMENT_CREATE_UPDATE_REQUEST:
      return ticketCommentCreateUpdateRequest(state, action);
    case actionTypes.TICKET_COMMENT_CREATE_UPDATE_SUCCESS:
      return ticketCommentCreateUpdateSuccess(state, action);
    case actionTypes.TICKET_COMMENT_CREATE_UPDATE_FAILURE:
      return ticketCommentCreateUpdateFailure(state, action);
    // Delete Comment
    case actionTypes.TICKET_COMMENT_DELETE_REQUEST:
      return ticketCommentDeleteRequest(state, action);
    case actionTypes.TICKET_COMMENT_DELETE_SUCCESS:
      return ticketCommentDeleteSuccess(state, action);
    case actionTypes.TICKET_COMMENT_DELETE_FAILURE:
      return ticketCommentDeleteFailure(state, action);
    // Image Setter and Clearer
    case actionTypes.TICKET_IMAGES_SETTER_AND_CLEARER:
      return ticketImagesSetterAndClearer(state, action);
    // Search
    case actionTypes.TICKET_SEARCH_REQUEST:
      return ticketSearchRequest(state, action);
    case actionTypes.TICKET_SEARCH_SUCCESS:
      return ticketSearchSuccess(state, action);
    case actionTypes.TICKET_SEARCH_FAILURE:
      return ticketSearchFailure(state, action);
    default:
      return state;
  }
};

export default ticketReducer;
