import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from '@material-ui/core';

// Actions
import {
  workspaceSetter,
  workspaceCreateUpdate,
  workspaceDelete,
} from '../../store/actions';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Widget from '../../components/Widget/Widget';
import Dialog from '../../components/Dialog/Dialog';
import { Typography } from '../../components/Wrappers/Wrappers';

// Pages
import CreateUpdateWorkspace from '../Workspace/CreateUpdateWorkspace';

// Styles
import useStyles from './dashboardStyles';

const Dashboard = (props) => {
  var classes = useStyles();

  // On Load
  useEffect(() => {
    document.title = `Dashboard | ${process.env.REACT_APP_NAME}`;
  }, []);

  // Global
  const {
    token,
    workspaces,
    isLoading,
    onClickSetWorkspace,
    onSubmitCreateUpdateWorkspace,
    onSubmitDeleteWorkspace,
  } = props;

  // Local
  const [
    openCreateUpdateWorkspace,
    setOpenCreateUpdateWorkspace,
  ] = React.useState(false);
  const [valueForm, setValueForm] = React.useState(null);

  const handleOpenCreateWorkspace = () => {
    setValueForm(null);
    setOpenCreateUpdateWorkspace(true);
  };

  const handleOpenEditWorkspace = (values) => {
    setValueForm(values);
    setOpenCreateUpdateWorkspace(true);
  };

  const handleCloseCreateOrEditWorkspace = () => {
    setOpenCreateUpdateWorkspace(false);
  };

  const handleClickWorkspace = (values) => {
    onClickSetWorkspace({
      currentWorkspace: values.workspace,
      currentWorkspaceUser: values.user,
      currentWorkspaceRole: values.role,
    }).then(() => {
      props.history.replace(`/workspace/${values.workspace.id}`);
    });
  };

  const handleSubmitCreateWorkspace = (values) => {
    const modifiedValues = {
      ...values,
    };
    if (values.id) {
      onSubmitCreateUpdateWorkspace({
        url: '/workspace/update',
        values: {
          token: token,
          formData: modifiedValues,
        },
      }).then(() => {
        handleCloseCreateOrEditWorkspace();
      });
    } else {
      onSubmitCreateUpdateWorkspace({
        url: '/workspace/create',
        values: {
          token: token,
          formData: modifiedValues,
        },
      }).then(() => {
        handleCloseCreateOrEditWorkspace();
      });
    }
  };

  const handleClickDeleteWorkspace = (id, name) => {
    const modifiedValues = {
      token: token,
      formData: { id: id },
    };
    const result = window.confirm(`Are you sure you want to delete ${name}?`);
    if (result) {
      onSubmitDeleteWorkspace(modifiedValues);
    }
  };

  return (
    <>
      <PageTitle
        title='Dashboard'
        button={
          <Button
            variant='outlined'
            size='medium'
            color='secondary'
            onClick={handleOpenCreateWorkspace}
          >
            Add workspace
          </Button>
        }
      />
      <Grid container spacing={4}>
        {workspaces.map((value, index) => {
          return (
            <Grid item lg={3} md={4} sm={6} xs={12} key={value.workspace.id}>
              <Widget
                title={value.workspace.name}
                upperTitle
                bodyClass={classes.fullHeightBody}
                className={classes.card}
                showEdit={
                  value.role.id === 1 || value.role.id === 2 ? true : false
                }
                showDelete={value.role.id === 1 ? true : false}
                onClickOpen={() => handleClickWorkspace(value)}
                onClickEdit={() =>
                  handleOpenEditWorkspace({
                    id: value.workspace.id,
                    name: value.workspace.name,
                    description: value.workspace.description,
                  })
                }
                onClickDelete={() =>
                  handleClickDeleteWorkspace(
                    value.workspace.id,
                    value.workspace.name,
                  )
                }
              >
                <div className={classes.subPart}>
                  <Grid
                    container
                    direction='row'
                    justify='space-between'
                    alignItems='center'
                  >
                    <Grid item xs={12}>
                      <Typography
                        color='text'
                        colorBrightness='secondary'
                        noWrap
                      >
                        You are
                      </Typography>
                      <Typography size='sm'>{`${value.user.firstName} ${value.user.lastName} (${value.role.name})`}</Typography>
                    </Grid>
                  </Grid>
                </div>
              </Widget>
            </Grid>
          );
        })}
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={openCreateUpdateWorkspace}
        handleClose={handleCloseCreateOrEditWorkspace}
        title={`${valueForm ? 'Edit' : 'Create'} Workspace`}
        handleSubmit={handleSubmitCreateWorkspace}
        isLoading={isLoading}
        initialValues={valueForm}
      >
        <CreateUpdateWorkspace />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    workspaces: state.workspace.workspaces,
    isLoading: state.workspace.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickSetWorkspace: (values) => dispatch(workspaceSetter(values)),
    onSubmitCreateUpdateWorkspace: (values) =>
      dispatch(workspaceCreateUpdate(values)),
    onSubmitDeleteWorkspace: (values) => dispatch(workspaceDelete(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
