import React from 'react';
import { Field } from 'react-final-form';

// Components
import Input from '../../components/Input/Input';

const CreateUpdateMilestone = () => {
  return (
    <>
      <Field
        component={Input}
        id='name'
        name='name'
        label='Name'
        type='text'
        margin='normal'
        fullWidth
        required
        autoFocus
      />
      <Field
        component={Input}
        id='description'
        name='description'
        label='Description'
        type='text'
        margin='normal'
        fullWidth
        multiline={true}
        rows={3}
        rowsMax={3}
      />
      <Field
        component={Input}
        id='startDate'
        name='startDate'
        label='Start Date'
        type='date'
        margin='normal'
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Field
        component={Input}
        id='endDate'
        name='endDate'
        label='End Date'
        type='date'
        margin='normal'
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
    </>
  );
};

export default CreateUpdateMilestone;
