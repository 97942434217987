import * as actionTypes from './actionTypes';
import { usePost, useGet } from '../utilities/webServices';
import { userLogout } from './userAction';
import { messageSetterAndClearer } from './globalAction';

// Create or Update
const workspaceCreateUpdateRequest = () => {
  return {
    type: actionTypes.WORKSPACE_CREATE_UPDATE_REQUEST,
  };
};

const workspaceCreateUpdateSuccess = (values) => {
  return {
    type: actionTypes.WORKSPACE_CREATE_UPDATE_SUCCESS,
    payload: {
      workspace: values.data,
    },
  };
};

const workspaceCreateUpdateFailure = () => {
  return {
    type: actionTypes.WORKSPACE_CREATE_UPDATE_FAILURE,
  };
};

// Read
const workspaceReadRequest = () => {
  return {
    type: actionTypes.WORKSPACE_READ_REQUEST,
  };
};

const workspaceReadSuccess = (values) => {
  return {
    type: actionTypes.WORKSPACE_READ_SUCCESS,
    payload: {
      workspaces: values.data,
    },
  };
};

const workspaceReadFailure = () => {
  return {
    type: actionTypes.WORKSPACE_READ_FAILURE,
  };
};

// Read Invitations
const workspaceInvitationReadRequest = () => {
  return {
    type: actionTypes.WORKSPACE_INVITATION_READ_REQUEST,
  };
};

const workspaceInvitationReadSuccess = (values) => {
  return {
    type: actionTypes.WORKSPACE_INVITATION_READ_SUCCESS,
    payload: {
      invitations: values.data,
    },
  };
};

const workspaceInvitationReadFailure = () => {
  return {
    type: actionTypes.WORKSPACE_INVITATION_READ_FAILURE,
  };
};

// Accept Invitation
const workspaceInvitationAcceptRequest = () => {
  return {
    type: actionTypes.WORKSPACE_INVITATION_ACCEPT_REQUEST,
  };
};

const workspaceInvitationAcceptSuccess = (values) => {
  return {
    type: actionTypes.WORKSPACE_INVITATION_ACCEPT_SUCCESS,
    payload: {
      invitation: values.data,
    },
  };
};

const workspaceInvitationAcceptFailure = () => {
  return {
    type: actionTypes.WORKSPACE_INVITATION_ACCEPT_FAILURE,
  };
};

// Read Sent Invitations
const workspaceSentInvitationReadRequest = () => {
  return {
    type: actionTypes.WORKSPACE_SENT_INVITATION_READ_REQUEST,
  };
};

const workspaceSentInvitationReadSuccess = (values) => {
  return {
    type: actionTypes.WORKSPACE_SENT_INVITATION_READ_SUCCESS,
    payload: {
      sentInvitations: values.data,
    },
  };
};

const workspaceSentInvitationReadFailure = () => {
  return {
    type: actionTypes.WORKSPACE_SENT_INVITATION_READ_FAILURE,
  };
};

// Delete
const workspaceDeleteRequest = () => {
  return {
    type: actionTypes.WORKSPACE_DELETE_REQUEST,
  };
};

const workspaceDeleteSuccess = (values) => {
  return {
    type: actionTypes.WORKSPACE_DELETE_SUCCESS,
    payload: {
      workspaceId: values.id,
    },
  };
};

const workspaceDeleteFailure = () => {
  return {
    type: actionTypes.WORKSPACE_DELETE_FAILURE,
  };
};

// Action Creators
// Create
export const workspaceCreateUpdate = (values) => {
  return async (dispatch) => {
    dispatch(workspaceCreateUpdateRequest());
    try {
      const response = await usePost(values.url, values.values);
      if (response.data) {
        dispatch(workspaceCreateUpdateSuccess(response));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(workspaceCreateUpdateFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(workspaceCreateUpdateFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Read
export const workspaceRead = (values) => {
  return async (dispatch) => {
    let result = null;
    dispatch(workspaceReadRequest());
    try {
      const response = await useGet('/workspace/list-all', values.token);
      if (response && response.data) {
        result = response.data;
        dispatch(workspaceReadSuccess(response));
      } else if ((response.status = 401)) {
        dispatch(userLogout());
      } else {
        dispatch(workspaceReadFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(workspaceReadFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
    return result;
  };
};

// Read Invitations
export const workspaceInvitationRead = (values) => {
  return async (dispatch) => {
    dispatch(workspaceInvitationReadRequest());
    try {
      const response = await useGet(
        '/workspace/received-invitations',
        values.token,
      );
      if (response && response.data) {
        dispatch(workspaceInvitationReadSuccess(response));
      } else if ((response.status = 401)) {
        dispatch(userLogout());
      } else {
        dispatch(workspaceInvitationReadFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(workspaceInvitationReadFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Accept Invitation
export const workspaceInvitationAccept = (values) => {
  return async (dispatch) => {
    let result = false;
    dispatch(workspaceInvitationAcceptRequest());
    try {
      const response = await usePost('/workspace/accept-invite', values);
      if (response.data) {
        result = true;
        dispatch(workspaceInvitationAcceptSuccess(response));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(workspaceInvitationAcceptFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(workspaceInvitationAcceptFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
    return result;
  };
};

// Read Sent Invitations
export const workspaceSentInvitationRead = (values) => {
  return async (dispatch) => {
    dispatch(workspaceSentInvitationReadRequest());
    try {
      const response = await useGet(
        `/workspace/${values.workspaceId}/sent-invitations`,
        values.token,
      );
      if (response && response.data) {
        dispatch(workspaceSentInvitationReadSuccess(response));
      } else if ((response.status = 401)) {
        dispatch(userLogout());
      } else {
        dispatch(workspaceSentInvitationReadFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(workspaceSentInvitationReadFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Delete
export const workspaceDelete = (values) => {
  return async (dispatch) => {
    dispatch(workspaceDeleteRequest());
    try {
      const response = await usePost('/workspace/delete', values);
      if (response.data) {
        dispatch(workspaceDeleteSuccess(values.formData));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(workspaceDeleteFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(workspaceDeleteFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};
