import React from 'react';
import { connect } from 'react-redux';

// Actions
import { userTokenValidation, workspaceClearer } from './store/actions';

// Routes
import Routes from './routes/Routes';

const App = (props) => {
  // App Router
  return <Routes {...props} />;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
    token: state.user.token,
    user: state.user.user,
    currentWorkspaceUser: state.global.currentWorkspaceUser,
    currentWorkspaceRole: state.global.currentWorkspaceRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadValidateToken: (token) => dispatch(userTokenValidation(token)),
    onLoadClearWorkspace: () => dispatch(workspaceClearer()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
