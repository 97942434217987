import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities/helpers';

const initialState = {
  teams: [],
  loading: false,
};

// Read
const teamReadRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const teamReadSuccess = (state, action) => {
  return updateObject(state, {
    teams: action.payload.teams,
    loading: false,
  });
};

const teamReadFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Invite User
const teamInviteUserRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const teamInviteUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

const teamInviteUserFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Edit User Role
const teamEditUserRoleRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const teamEditUserRoleSuccess = (state, action) => {
  let modifyTeamsForEditRole = [...state.teams];

  const teamIndex = modifyTeamsForEditRole.findIndex(
    (team) => parseInt(action.payload.user.id) === parseInt(team.id),
  );

  if (teamIndex >= 0) {
    modifyTeamsForEditRole.splice(teamIndex, 1, action.payload.user);
  }

  return updateObject(state, {
    teams: modifyTeamsForEditRole,
    loading: false,
  });
};

const teamEditUserRoleFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Delete User
const teamDeleteUserRequest = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

const teamDeleteUserSuccess = (state, action) => {
  let modifyTeamsForDeleteUser = [...state.teams];

  const deletedUserForTeams = modifyTeamsForDeleteUser.filter(
    (team) => parseInt(action.payload.userId) !== parseInt(team.id),
  );

  return updateObject(state, {
    teams: deletedUserForTeams,
    loading: false,
  });
};

const teamDeleteUserFailure = (state, action) => {
  return updateObject(state, {
    loading: false,
  });
};

// Reducer
const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    // Read
    case actionTypes.TEAM_READ_REQUEST:
      return teamReadRequest(state, action);
    case actionTypes.TEAM_READ_SUCCESS:
      return teamReadSuccess(state, action);
    case actionTypes.TEAM_READ_FAILURE:
      return teamReadFailure(state, action);
    // Invite User
    case actionTypes.TEAM_INVITE_USER_REQUEST:
      return teamInviteUserRequest(state, action);
    case actionTypes.TEAM_INVITE_USER_SUCCESS:
      return teamInviteUserSuccess(state, action);
    case actionTypes.TEAM_INVITE_USER_FAILURE:
      return teamInviteUserFailure(state, action);
    // Edit User Role
    case actionTypes.TEAM_EDIT_USER_ROLE_REQUEST:
      return teamEditUserRoleRequest(state, action);
    case actionTypes.TEAM_EDIT_USER_ROLE_SUCCESS:
      return teamEditUserRoleSuccess(state, action);
    case actionTypes.TEAM_EDIT_USER_ROLE_FAILURE:
      return teamEditUserRoleFailure(state, action);
    // Delete User
    case actionTypes.TEAM_USER_DELETE_REQUEST:
      return teamDeleteUserRequest(state, action);
    case actionTypes.TEAM_USER_DELETE_SUCCESS:
      return teamDeleteUserSuccess(state, action);
    case actionTypes.TEAM_USER_DELETE_FAILURE:
      return teamDeleteUserFailure(state, action);
    default:
      return state;
  }
};

export default teamReducer;
