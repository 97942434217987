import React, { useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  Fade,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Actions
import { userLogin, userRegisteration } from '../../store/actions';

// Components
import Input from '../../components/Input/Input';

// Logo
import logo from '../../assets/images/logo.png';

// Styles
import useStyles from './loginStyles';

const Login = (props) => {
  var classes = useStyles();

  // Global
  const {
    message,
    messageType,
    isLoading,
    onSubmitUserRegistration,
    onSubmitUserLogin,
  } = props;

  // Local
  var [activeTabId, setActiveTabId] = useState(0);

  // On Load
  useEffect(() => {
    document.title = `${activeTabId === 0 ? 'Login' : 'Register'} | ${
      process.env.REACT_APP_NAME
    }`;
  }, [activeTabId]);

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt='logo' className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          {/* Tabs */}
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor='primary'
            textColor='primary'
            centered
          >
            <Tab label='Login' classes={{ root: classes.tab }} />
            <Tab label='New User' classes={{ root: classes.tab }} />
          </Tabs>
          {activeTabId === 0 && (
            // Login
            <React.Fragment>
              <Typography variant='h1' className={classes.greeting}>
                Hello there!
              </Typography>
              <Typography variant='h2' className={classes.subGreeting}>
                Sign in to Ticket Manager
              </Typography>
              <Fade in={true}>
                <Typography
                  color={messageType === 1 ? 'secondary' : 'primary'}
                  className={classes.errorMessage}
                >
                  {message && message}
                </Typography>
              </Fade>
              <Form
                onSubmit={onSubmitUserLogin}
                render={({ handleSubmit, values, ...props }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      component={Input}
                      id='userName'
                      name='userName'
                      placeholder='Username'
                      type='text'
                      margin='normal'
                      fullWidth
                      required
                      autoFocus
                    />
                    <Field
                      component={Input}
                      id='password'
                      name='password'
                      placeholder='Password'
                      type='password'
                      margin='normal'
                      fullWidth
                      required
                    />
                    <div className={classes.creatingButtonContainer}>
                      {isLoading ? (
                        <CircularProgress size={26} />
                      ) : (
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          size='large'
                          fullWidth
                          disabled={!values.userName || !values.password}
                        >
                          Login
                        </Button>
                      )}
                    </div>
                  </form>
                )}
              />
            </React.Fragment>
          )}
          {activeTabId === 1 && (
            // New User
            <>
              <Typography variant='h1' className={classes.greeting}>
                Welcome!
              </Typography>
              <Typography variant='h2' className={classes.subGreeting}>
                Create your account
              </Typography>
              <Fade in={true}>
                <Typography
                  color={messageType === 1 ? 'secondary' : 'primary'}
                  className={classes.errorMessage}
                >
                  {message && message}
                </Typography>
              </Fade>
              <Form
                onSubmit={onSubmitUserRegistration}
                render={({ handleSubmit, values, form }) => (
                  <form
                    onSubmit={async (event) => {
                      event.preventDefault();
                      const result = await handleSubmit(values);
                      result && form.reset();
                    }}
                  >
                    <Field
                      component={Input}
                      id='firstName'
                      name='firstName'
                      placeholder='First Name'
                      type='text'
                      margin='normal'
                      fullWidth
                      required
                      autoFocus
                    />
                    <Field
                      component={Input}
                      id='lastName'
                      name='lastName'
                      placeholder='Last Name'
                      type='text'
                      margin='normal'
                      fullWidth
                      required
                    />
                    <Field
                      component={Input}
                      id='userName'
                      name='userName'
                      placeholder='Username'
                      type='text'
                      margin='normal'
                      fullWidth
                      required
                    />
                    <Field
                      component={Input}
                      id='companyName'
                      name='companyName'
                      placeholder='Company Name (Optional)'
                      type='text'
                      margin='normal'
                      fullWidth
                    />
                    <Field
                      component={Input}
                      id='emailId'
                      name='emailId'
                      placeholder='Email Address'
                      type='email'
                      margin='normal'
                      fullWidth
                      required
                    />
                    <Field
                      component={Input}
                      id='password'
                      name='password'
                      placeholder='Password'
                      type='password'
                      margin='normal'
                      fullWidth
                      required
                    />
                    <div className={classes.creatingButtonContainer}>
                      {isLoading ? (
                        <CircularProgress size={26} />
                      ) : (
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          size='large'
                          fullWidth
                          disabled={
                            !values.firstName ||
                            !values.lastName ||
                            !values.userName ||
                            !values.emailId ||
                            !values.password
                          }
                        >
                          Register
                        </Button>
                      )}
                    </div>
                  </form>
                )}
              />
            </>
          )}
        </div>
      </div>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    message: state.user.message,
    messageType: state.user.messageType,
    isLoading: state.user.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitUserRegistration: (values) => dispatch(userRegisteration(values)),
    onSubmitUserLogin: (values) => dispatch(userLogin(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
