import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';

function ReadWorkspace(props) {
  // Global
  const { currentWorkspace } = props;

  // On Load/Update
  useEffect(() => {
    document.title = `${currentWorkspace.name} | ${process.env.REACT_APP_NAME}`;
  }, [currentWorkspace]);

  return (
    <>
      <PageTitle title={currentWorkspace.name} />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    currentWorkspace: state.global.currentWorkspace,
  };
};

export default connect(mapStateToProps)(ReadWorkspace);
