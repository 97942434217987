import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, IconButton } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';

// Actions
import {
  teamInviteUser,
  workspaceSentInvitationRead,
  teamEditUserRole,
  teamDeleteUser,
} from '../../store/actions';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Dialog from '../../components/Dialog/Dialog';

// Pages
import InviteUser from './InviteUser';
import UpdateUserRoles from './UpdateUserRoles';

const ReadTeam = (props) => {
  // On Load
  useEffect(() => {
    document.title = `Team | ${process.env.REACT_APP_NAME}`;
  }, []);

  // Local
  const [openInviteUser, setOpenInviteUser] = React.useState(false);
  const [openUpdateUserRole, setOpenUpdateUserRole] = React.useState(false);
  const [valueForm, setValueForm] = React.useState(null);

  const handleOpenInviteUser = () => {
    setOpenInviteUser(true);
  };

  const handleCloseInviteUser = () => {
    setOpenInviteUser(false);
  };

  const handleOpenEditUserRole = (values) => {
    setValueForm(values);
    setOpenUpdateUserRole(true);
  };

  const handleCloseEditRoles = () => {
    setValueForm(null);
    setOpenUpdateUserRole(false);
  };

  const handleSubmitInviteUser = (values) => {
    const modifiedValues = {
      ...values,
      forWorkspaceId: parseInt(props.currentWorkspace.id),
    };
    props
      .onSubmitInviteUser({
        token: props.token,
        formData: modifiedValues,
      })
      .then(() => {
        props
          .onLoadInvitedUsers({
            token: props.token,
            workspaceId: parseInt(props.currentWorkspace.id),
          })
          .then(() => {
            handleCloseInviteUser();
          });
      });
  };

  const handleSubmitEditRoles = (values) => {
    const modifiedValues = {
      id: parseInt(values.id),
      roleId: parseInt(values.forRoleId),
    };
    props
      .onSubmitEditRole({
        token: props.token,
        formData: modifiedValues,
      })
      .then(() => {
        handleCloseEditRoles();
      });
  };

  const handleSubmitDeleteUser = (values) => {
    const modifiedValues = {
      id: values.id,
    };
    const result = window.confirm(
      `Are you sure you want to delete ${values.userName}?`,
    );
    if (result) {
      props.onSubmitDeleteUser({
        token: props.token,
        formData: modifiedValues,
      });
    }
  };

  // Rows 1
  let tableStructure = [];
  if (props.teams) {
    tableStructure = props.teams.map((team) => {
      return [
        `${team.user.firstName} ${team.user.lastName}`,
        team.user.userName,
        team.user.emailId,
        team.user.emailVerified ? 'Yes' : 'No',
        team.role.name,
        props.currentWorkspaceRole.id !== 4 &&
          props.currentWorkspaceRole.id !== 3 && {
            id: team.id,
            forRoleId: team.role.id,
            userName: team.user.userName,
          },
      ];
    });
  }

  // Rows 2
  let tableForInvitedUser = [];
  if (props.sentInvitations) {
    tableForInvitedUser = props.sentInvitations.map((team) => {
      return [
        team.toEmailid,
        team.createdBy.userName,
        team.accepted ? 'Yes' : 'No',
        team.forRole.name,
      ];
    });
  }

  // Columns 1
  const columns = ['Name', 'Username', 'Email Address', 'Verified', 'Role'];

  if (
    props.currentWorkspaceRole.id !== 4 &&
    props.currentWorkspaceRole.id !== 3
  )
    columns.push({
      name: 'Actions',
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                onClick={() => handleOpenEditUserRole(value)}
                color='primary'
                aria-label='edit-user-role'
                component='span'
                size='small'
                disabled={parseInt(value.forRoleId) === 1 ? true : false}
              >
                <EditIcon fontSize='small' />
              </IconButton>
              <IconButton
                onClick={() => handleSubmitDeleteUser(value)}
                color='primary'
                aria-label='delete-user'
                component='span'
                size='small'
                disabled={parseInt(value.forRoleId) === 1 ? true : false}
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
            </>
          );
        },
      },
    });

  // Columns 2
  const invitedColumns = ['Email Address', 'Invited By', 'Accepted', 'Role'];

  // Config
  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
  };

  return (
    <>
      <PageTitle
        title={'Team'}
        button={
          props.currentWorkspaceRole.id !== 4 &&
          props.currentWorkspaceRole.id !== 3 && (
            <Button
              variant='outlined'
              size='medium'
              color='secondary'
              onClick={handleOpenInviteUser}
            >
              Invite New User
            </Button>
          )
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title='All Users'
            data={tableStructure}
            columns={columns}
            options={options}
          />
        </Grid>
        {props.currentWorkspaceRole.id !== 4 &&
          props.currentWorkspaceRole.id !== 3 && (
            <Grid item xs={12}>
              <MUIDataTable
                title='All Invited Users'
                data={tableForInvitedUser}
                columns={invitedColumns}
                options={options}
              />
            </Grid>
          )}
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={openInviteUser}
        handleClose={handleCloseInviteUser}
        title='Invite User'
        handleSubmit={handleSubmitInviteUser}
        isLoading={props.isLoading}
      >
        <InviteUser options={props.roles} />
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={openUpdateUserRole}
        handleClose={handleCloseEditRoles}
        title='Edit Roles'
        handleSubmit={handleSubmitEditRoles}
        isLoading={props.isLoading}
        initialValues={valueForm}
      >
        <UpdateUserRoles options={props.roles} />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    roles: state.global.roles,
    teams: state.team.teams,
    sentInvitations: state.workspace.sentInvitations,
    isLoading: state.team.loading,
    currentWorkspace: state.global.currentWorkspace,
    currentWorkspaceRole: state.global.currentWorkspaceRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitInviteUser: (values) => dispatch(teamInviteUser(values)),
    onLoadInvitedUsers: (values) =>
      dispatch(workspaceSentInvitationRead(values)),
    onSubmitEditRole: (values) => dispatch(teamEditUserRole(values)),
    onSubmitDeleteUser: (values) => dispatch(teamDeleteUser(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadTeam);
