import React from 'react';
import { Field } from 'react-final-form';

// Components
import Select from '../../components/Select/Select';

const UpdateUserRoles = (props) => {
  return (
    <>
      <Field
        component={Select}
        options={props.options}
        id='forRoleId'
        name='forRoleId'
        label='Role'
        margin='normal'
        hasEmptyOption={true}
        fullWidth
        required
        autofocus
      />
    </>
  );
};

export default UpdateUserRoles;
