import React from 'react';
import { Field } from 'react-final-form';

// Components
import Input from '../../components/Input/Input';

const CreateUpdateWorkspace = () => {
  return (
    <>
      <Field
        component={Input}
        id='name'
        name='name'
        label='Name'
        type='text'
        margin='normal'
        fullWidth
        required
        autoFocus
      />
      <Field
        component={Input}
        id='description'
        name='description'
        label='Description'
        margin='normal'
        fullWidth
        multiline
        rows='2'
      />
    </>
  );
};

export default CreateUpdateWorkspace;
