import * as actionTypes from './actionTypes';
import { usePost, useGet } from '../utilities/webServices';
import { userLogout } from './userAction';
import { messageSetterAndClearer } from './globalAction';

// Create or Update
const milestoneCreateUpdateRequest = () => {
  return {
    type: actionTypes.MILESTONE_CREATE_UPDATE_REQUEST,
  };
};

const milestoneCreateUpdateSuccess = (values) => {
  return {
    type: actionTypes.MILESTONE_CREATE_UPDATE_SUCCESS,
    payload: {
      milestone: values.data,
    },
  };
};

const milestoneCreateUpdateFailure = () => {
  return {
    type: actionTypes.MILESTONE_CREATE_UPDATE_FAILURE,
  };
};

// Read
const milestoneReadRequest = () => {
  return {
    type: actionTypes.MILESTONE_READ_REQUEST,
  };
};

const milestoneReadSuccess = (values) => {
  return {
    type: actionTypes.MILESTONE_READ_SUCCESS,
    payload: {
      milestones: values.data,
    },
  };
};

const milestoneReadFailure = () => {
  return {
    type: actionTypes.MILESTONE_READ_FAILURE,
  };
};

// Delete
const milestoneDeleteRequest = () => {
  return {
    type: actionTypes.MILESTONE_DELETE_REQUEST,
  };
};

const milestoneDeleteSuccess = (values) => {
  return {
    type: actionTypes.MILESTONE_DELETE_SUCCESS,
    payload: {
      milestoneId: values.id,
    },
  };
};

const milestoneDeleteFailure = () => {
  return {
    type: actionTypes.MILESTONE_DELETE_FAILURE,
  };
};

// Action Creators
// Create or Update
export const milestoneCreateUpdate = (values) => {
  return async (dispatch) => {
    dispatch(milestoneCreateUpdateRequest());
    try {
      const response = await usePost(values.url, values.values);
      if (response.data && response.data.id) {
        dispatch(milestoneCreateUpdateSuccess(response));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(milestoneCreateUpdateFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(milestoneCreateUpdateFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Read
export const milestoneRead = (values) => {
  return async (dispatch) => {
    dispatch(milestoneReadRequest());
    try {
      const response = await useGet(
        `/milestone/list-all/${values.workspaceId}`,
        values.token,
      );
      if (response && response.data) {
        dispatch(milestoneReadSuccess(response));
      } else if ((response.status = 401)) {
        dispatch(userLogout());
      } else {
        dispatch(milestoneReadFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(milestoneReadFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Delete
export const milestoneDelete = (values) => {
  return async (dispatch) => {
    dispatch(milestoneDeleteRequest());
    try {
      const response = await usePost('milestone/delete', values);
      if (response.data) {
        dispatch(milestoneDeleteSuccess(values.formData));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(milestoneDeleteFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(milestoneDeleteFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};
