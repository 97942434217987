import * as actionTypes from './actionTypes';
import { usePost, useGet } from '../utilities/webServices';
import { userLogout } from './userAction';
import { messageSetterAndClearer } from './globalAction';

// Create or Update
const ticketCreateUpdateRequest = () => {
  return {
    type: actionTypes.TICKET_CREATE_UPDATE_REQUEST,
  };
};

const ticketCreateUpdateSuccess = (values) => {
  return {
    type: actionTypes.TICKET_CREATE_UPDATE_SUCCESS,
    payload: {
      ticket: values.data,
    },
  };
};

const ticketCreateUpdateFailure = () => {
  return {
    type: actionTypes.TICKET_CREATE_UPDATE_FAILURE,
  };
};

// Read
const ticketReadRequest = () => {
  return {
    type: actionTypes.TICKET_READ_REQUEST,
  };
};

const ticketReadSuccess = (values) => {
  return {
    type: actionTypes.TICKET_READ_SUCCESS,
    payload: {
      tickets: values.data.id,
    },
  };
};

const ticketReadFailure = () => {
  return {
    type: actionTypes.TICKET_READ_FAILURE,
  };
};

// Read By ID
const ticketReadByIdRequest = () => {
  return {
    type: actionTypes.TICKET_READ_BY_ID_REQUEST,
  };
};

const ticketReadByIdSuccess = (values) => {
  return {
    type: actionTypes.TICKET_READ_BY_ID_SUCCESS,
    payload: {
      currentTicket: values.data,
    },
  };
};

const ticketReadByIdFailure = () => {
  return {
    type: actionTypes.TICKET_READ_BY_ID_FAILURE,
  };
};

// Delete
const ticketDeleteRequest = () => {
  return {
    type: actionTypes.TICKET_DELETE_REQUEST,
  };
};

const ticketDeleteSuccess = (values) => {
  return {
    type: actionTypes.TICKET_DELETE_SUCCESS,
    payload: {
      ticketId: values.id,
    },
  };
};

const ticketDeleteFailure = () => {
  return {
    type: actionTypes.TICKET_DELETE_FAILURE,
  };
};

// Create or Update Comment
const ticketCommentCreateUpdateRequest = () => {
  return {
    type: actionTypes.TICKET_COMMENT_CREATE_UPDATE_REQUEST,
  };
};

const ticketCommentCreateUpdateSuccess = (values) => {
  return {
    type: actionTypes.TICKET_COMMENT_CREATE_UPDATE_SUCCESS,
    payload: {
      ticket: values.data,
    },
  };
};

const ticketCommentCreateUpdateFailure = () => {
  return {
    type: actionTypes.TICKET_COMMENT_CREATE_UPDATE_FAILURE,
  };
};

// Delete Comment
const ticketCommentDeleteRequest = () => {
  return {
    type: actionTypes.TICKET_COMMENT_DELETE_REQUEST,
  };
};

const ticketCommentDeleteSuccess = (values) => {
  return {
    type: actionTypes.TICKET_COMMENT_DELETE_SUCCESS,
    payload: {
      commentId: values.id,
    },
  };
};

const ticketCommentDeleteFailure = () => {
  return {
    type: actionTypes.TICKET_COMMENT_DELETE_FAILURE,
  };
};

// Images Setter and Clearer
const setAndClearTicketImages = (values) => {
  return {
    type: actionTypes.TICKET_IMAGES_SETTER_AND_CLEARER,
    payload: {
      imageId: values.id,
    },
  };
};

// Search
const ticketSearchRequest = () => {
  return {
    type: actionTypes.TICKET_SEARCH_REQUEST,
  };
};

const ticketSearchSuccess = (values) => {
  return {
    type: actionTypes.TICKET_SEARCH_SUCCESS,
    payload: {
      search: values,
    },
  };
};

const ticketSearchFailure = () => {
  return {
    type: actionTypes.TICKET_SEARCH_FAILURE,
  };
};

// Action Creators
// Create or Update
export const ticketCreateUpdate = (values) => {
  return async (dispatch) => {
    dispatch(ticketCreateUpdateRequest());
    try {
      const response = await usePost(values.url, values.values);
      if (response.data && response.data.id) {
        dispatch(ticketCreateUpdateSuccess(response));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(ticketCreateUpdateFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(ticketCreateUpdateFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Read
export const ticketRead = (values) => {
  return async (dispatch) => {
    dispatch(ticketReadRequest());
    try {
      const response = await useGet(
        `/ticket/list-all/${values.workspaceId}/${values.groupBy}/${values.sortBy}`,
        values.token,
      );
      if (response && response.data) {
        dispatch(ticketReadSuccess(response));
      } else if ((response.status = 401)) {
        dispatch(userLogout());
      } else {
        dispatch(ticketReadFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(ticketReadFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Read By ID
export const ticketReadById = (values) => {
  return async (dispatch) => {
    let result = null;
    dispatch(ticketReadByIdRequest());
    try {
      const response = await useGet(
        `/ticket/get-info/${values.ticketId}`,
        values.token,
      );
      if (response && response.data) {
        result = response.data;
        dispatch(ticketReadByIdSuccess(response));
      } else if ((response.status = 401)) {
        dispatch(userLogout());
      } else {
        dispatch(ticketReadByIdFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(ticketReadByIdFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
    return result;
  };
};

// Delete
export const ticketDelete = (values) => {
  return async (dispatch) => {
    let result = false;
    dispatch(ticketDeleteRequest());
    try {
      const response = await usePost('/ticket/delete', values);
      if (response.data) {
        result = true;
        dispatch(ticketDeleteSuccess(values.formData));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(ticketDeleteFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(ticketDeleteFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
    return result;
  };
};

// Create or Update Comment
export const ticketCommentCreateUpdate = (values) => {
  return async (dispatch) => {
    let result = false;
    dispatch(ticketCommentCreateUpdateRequest());
    try {
      const response = await usePost(values.url, values.values);
      if (response.data && response.data.id) {
        result = true;
        dispatch(ticketCommentCreateUpdateSuccess(response));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(ticketCommentCreateUpdateFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(ticketCommentCreateUpdateFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
    return result;
  };
};

// Delete Comment
export const ticketCommentDelete = (values) => {
  return async (dispatch) => {
    let result = false;
    dispatch(ticketCommentDeleteRequest());
    try {
      const response = await usePost('ticket-comment/delete', values);
      if (response.data) {
        result = true;
        dispatch(ticketCommentDeleteSuccess(values.formData));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(ticketCommentDeleteFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(ticketCommentDeleteFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
    return result;
  };
};

// Images Setter and Clearer
export const ticketImagesSetterAndClearer = (values) => {
  return (dispatch) => {
    dispatch(setAndClearTicketImages(values));
  };
};

// Search
export const ticketSearch = (values) => {
  return async (dispatch) => {
    dispatch(ticketSearchRequest());
    try {
      const response = await usePost('ticket/search', values);
      if (response.data) {
        dispatch(ticketSearchSuccess(response.data));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(ticketSearchFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(ticketSearchFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Search Clearer
export const ticketSearchClearer = (values) => {
  return (dispatch) => {
    dispatch(ticketSearchSuccess([]));
  };
};
