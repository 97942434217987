import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import classnames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';

// Actions
import {
  userLogout,
  workspaceRead,
  workspaceSetter,
  workspaceInvitationRead,
  workspaceSentInvitationRead,
  workspaceInvitationAccept,
  ticketRead,
  teamRead,
  ticketSearch,
  milestoneRead,
  statusRead,
  rolesFetcher,
  prioritiesFetcher,
} from '../../store/actions';

// Components
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
import Loader from '../Loader/Loader';

// Context
import { useLayoutState } from '../../contexts/LayoutContext';

// Pages
import Dashboard from '../../pages/Dashboard/Dashboard';
import ViewWorkspace from '../../pages/Workspace/ViewWorkspace';
import Ticket from '../../pages/Ticket/ReadTicket';
import ViewTicket from '../../pages/Ticket/ViewTicket';
import Team from '../../pages/Team/ReadTeam';
import Milestone from '../../pages/Milestone/ReadMilestone';
import Status from '../../pages/Status/ReadStatus';

// Styles
import useStyles from './layoutStyles';
import 'react-toastify/dist/ReactToastify.css';

const Layout = (props) => {
  var classes = useStyles();

  // Global
  var layoutState = useLayoutState();
  const {
    token,
    user,
    workspaces,
    invitations,
    currentWorkspace,
    currentWorkspaceRole,
    search,
    message,
    messageType,
    isWorkspaceLoading,
    isTicketLoading,
    isStatusLoading,
    isTeamLoading,
    isMilestoneLoading,
    onLoadWorkspaces,
    onLoadWorkspaceInvitations,
    onLoadStatuses,
    onLoadTeams,
    onLoadRoles,
    onLoadInvitedUsers,
    onLoadPriorities,
    onLoadMilestones,
    onLoadTickets,
    onClickLogoutUser,
    onClickSetWorkspace,
    onClickAcceptInvitation,
    onSearchTickets,
  } = props;

  // On Load
  useEffect(() => {
    const loadInitialData = async () => {
      const response = await onLoadWorkspaces({ token: token });
      if (response) {
        onLoadRoles({ token: token });
        onLoadPriorities({ token: token });
        onLoadWorkspaceInvitations({ token: token });
      }
    };

    loadInitialData();

    // eslint-disable-next-line
  }, []);

  // On Update of currentWorkspace
  useEffect(() => {
    const reloadWorkspaceData = () => {
      const values = {
        token: token,
        workspaceId: currentWorkspace.id,
      };
      const ticketValues = {
        token: token,
        workspaceId: currentWorkspace.id,
        groupBy: 'id',
        sortBy: 'displayId_ASC',
      };
      onLoadTickets(ticketValues);
      onLoadMilestones(values);
      onLoadTeams(values);
      if (currentWorkspaceRole.id !== 4 && currentWorkspaceRole !== 3)
        onLoadInvitedUsers(values);
      if (currentWorkspaceRole.id !== 4) onLoadStatuses(values);
    };
    if (currentWorkspace && currentWorkspace.id) reloadWorkspaceData();
    // eslint-disable-next-line
  }, [currentWorkspace]);

  // On Update of workspaceId in URL
  useEffect(() => {
    if (
      workspaces &&
      props.match.params.workspaceId &&
      parseInt(props.match.params.workspaceId) !== parseInt(currentWorkspace.id)
    ) {
      const result = workspaces.filter(
        (workspace) =>
          parseInt(workspace.workspace.id) ===
          parseInt(props.match.params.workspaceId),
      );
      if (result.length > 0) {
        onClickSetWorkspace({
          currentWorkspace: result && result[0].workspace,
          currentWorkspaceUser: result && result[0].user,
          currentWorkspaceRole: result && result[0].role,
        });
      }
    }

    // eslint-disable-next-line
  }, [props.match.params.workspaceId, workspaces]);

  // On Update of Message for Notification
  useEffect(() => {
    if (message && messageType) {
      switch (messageType) {
        case 1:
          toast.success(message, {
            className: classes.notificationSuccess,
          });
          break;
        case 2:
          toast.error(message, {
            className: classes.notificationError,
          });
          break;
        default:
          return false;
      }
    }
  }, [
    message,
    messageType,
    classes.notificationSuccess,
    classes.notificationError,
  ]);

  const handleClickWorkspace = (values) => {
    onClickSetWorkspace({
      currentWorkspace: values.workspace,
      currentWorkspaceUser: values.user,
      currentWorkspaceRole: values.role,
    }).then(() => {
      props.history.replace(`/workspace/${values.workspace.id}`);
    });
  };

  const handleSearch = (e) => {
    e = e || window.event;
    if (parseInt(e.keyCode) === 13) {
      var elem = e.srcElement || e.target;
      onSearchTickets({
        token: token,
        formData: {
          workspaceId: currentWorkspace.id,
          keyword: elem.value,
        },
      }).then(() => {
        props.history.replace(`/workspace/${currentWorkspace.id}/ticket`);
      });
    }
  };

  return (
    <>
      <Loader
        open={
          isWorkspaceLoading ||
          isTicketLoading ||
          isStatusLoading ||
          isTeamLoading ||
          isMilestoneLoading
        }
      />
      <div className={classes.root}>
        <Header
          history={props.history}
          token={token}
          user={user}
          onClickLogoutUser={onClickLogoutUser}
          invitations={invitations}
          onClickAcceptInvitation={onClickAcceptInvitation}
          onLoadWorkspaces={onLoadWorkspaces}
          handleSearch={handleSearch}
          search={search}
        />
        <Sidebar
          workspaces={workspaces}
          currentWorkspace={currentWorkspace}
          currentWorkspaceRole={currentWorkspaceRole}
          handleClickWorkspace={handleClickWorkspace}
          replace={props.history.replace}
        />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <ToastContainer
            position='bottom-left'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <Switch>
            <Route path='/dashboard' component={Dashboard} />
            <Route
              exact
              path='/workspace/:workspaceId/ticket/:ticketId'
              component={ViewTicket}
            />
            <Route
              exact
              path='/workspace/:workspaceId/ticket'
              component={Ticket}
            />
            <Route
              exact
              path='/workspace/:workspaceId/milestone'
              component={Milestone}
            />
            <Route exact path='/workspace/:workspaceId/team' component={Team} />
            {props.currentWorkspaceRole.id !== 4 && (
              <Route
                exact
                path='/workspace/:workspaceId/status'
                component={Status}
              />
            )}
            <Route
              exact
              path='/workspace/:workspaceId'
              component={ViewWorkspace}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    user: state.user.user,
    workspaces: state.workspace.workspaces,
    invitations: state.workspace.invitations,
    currentWorkspace: state.global.currentWorkspace,
    currentWorkspaceRole: state.global.currentWorkspaceRole,
    search: state.ticket.search,
    message: state.global.message,
    messageType: state.global.messageType,
    isWorkspaceLoading: state.workspace.loading,
    isTicketLoading: state.ticket.loading,
    isStatusLoading: state.status.loading,
    isTeamLoading: state.team.loading,
    isMilestoneLoading: state.milestone.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadWorkspaces: (values) => dispatch(workspaceRead(values)),
    onLoadWorkspaceInvitations: (values) =>
      dispatch(workspaceInvitationRead(values)),
    onLoadStatuses: (values) => dispatch(statusRead(values)),
    onLoadTeams: (values) => dispatch(teamRead(values)),
    onLoadRoles: (values) => dispatch(rolesFetcher(values)),
    onLoadInvitedUsers: (values) =>
      dispatch(workspaceSentInvitationRead(values)),
    onLoadPriorities: (values) => dispatch(prioritiesFetcher(values)),
    onLoadMilestones: (values) => dispatch(milestoneRead(values)),
    onLoadTickets: (values) => dispatch(ticketRead(values)),
    onClickLogoutUser: () => dispatch(userLogout()),
    onClickSetWorkspace: (values) => dispatch(workspaceSetter(values)),
    onClickAcceptInvitation: (values) =>
      dispatch(workspaceInvitationAccept(values)),
    onSearchTickets: (values) => dispatch(ticketSearch(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
