import React from 'react';
import { Field } from 'react-final-form';

// Components
import TextEditor from '../../components/TextEditor/TextEditor';

const AddComment = (props) => {
  return <Field component={TextEditor} id='comment' name='comment' />;
};

export default AddComment;
