import React, { useState, useEffect } from 'react';
import { Drawer, IconButton, List } from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  Home as HomeIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Flag as FlagIcon,
  Group as GroupIcon,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// Components
import SidebarLink from './components/SidebarLink/SidebarLink';
import Dot from './components/Dot';

// Context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../contexts/LayoutContext';

// Styles
import useStyles from './sidebarStyles';

const Sidebar = (props) => {
  var classes = useStyles();
  var theme = useTheme();

  // Global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // Local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const sidebarStructure = [
    {
      id: 1,
      label: 'Tickets',
      link: `/workspace/${
        props.currentWorkspace && props.currentWorkspace.id
      }/ticket`,
      icon: <ConfirmationNumberIcon />,
    },
    {
      id: 2,
      label: 'Milestones',
      link: `/workspace/${
        props.currentWorkspace && props.currentWorkspace.id
      }/milestone`,
      icon: <FlagIcon />,
    },
    {
      id: 3,
      label: 'Team',
      link: `/workspace/${
        props.currentWorkspace && props.currentWorkspace.id
      }/team`,
      icon: <GroupIcon />,
    },
    props.currentWorkspaceRole.id !== 4
      ? {
          id: 4,
          label: 'Settings',
          link: '',
          icon: <SettingsIcon />,
          children: [
            {
              label: 'Statuses',
              link: `/workspace/${
                props.currentWorkspace && props.currentWorkspace.id
              }/status`,
            },
          ],
        }
      : {
          id: 4,
          label: 'Settings',
          link: '',
          icon: <SettingsIcon />,
          disabled: true,
        },
  ];

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        <SidebarLink
          key={0}
          location={props.location}
          label='Dashboard'
          link='/dashboard'
          icon={<HomeIcon />}
          isSidebarOpened={isSidebarOpened}
        />
        {props.currentWorkspace.id
          ? sidebarStructure.map((link) => (
              <SidebarLink
                key={link.id}
                location={props.location}
                isSidebarOpened={isSidebarOpened}
                {...link}
              />
            ))
          : null}
        <SidebarLink key='dividerOne' type='divider' />
        {props.workspaces.length > 0 && (
          <SidebarLink
            key='workspacesTitle'
            type='title'
            label='WORKSPACES'
            isSidebarOpened={isSidebarOpened}
          />
        )}
        {props.workspaces.map((value) => (
          <SidebarLink
            key={value.workspace.id}
            label={value.workspace.name}
            isActive={
              value.workspace.id === props.currentWorkspace.id ? true : false
            }
            icon={
              <Dot
                size={
                  value.workspace.id === props.currentWorkspace.id
                    ? 'medium'
                    : 'small'
                }
                color={
                  value.workspace.id === props.currentWorkspace.id
                    ? 'primary'
                    : 'secondary'
                }
              />
            }
            handleClick={() =>
              props.handleClickWorkspace({
                workspace: value.workspace,
                user: value.user,
                role: value.role,
              })
            }
            isSidebarOpened={isSidebarOpened}
          />
        ))}
      </List>
    </Drawer>
  );

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
};

export default withRouter(Sidebar);
