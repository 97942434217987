import React from 'react';
import { Field } from 'react-final-form';
import { Grid } from '@material-ui/core';

// Components
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import TextEditor from '../../components/TextEditor/TextEditor';

const CreateUpdateTicket = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item sm={12} xs={12}>
        <Field
          component={Input}
          id='title'
          name='title'
          label='Title'
          type='text'
          margin='normal'
          fullWidth
          required
          autoFocus
        />
      </Grid>
      <Grid item sm={12} xs={12}>
        <Field component={TextEditor} id='description' name='description' />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Field
          component={Select}
          options={props.statuses}
          id='statusId'
          name='statusId'
          label='Status'
          fullWidth
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Field
          component={Select}
          options={props.priorities}
          id='priorityId'
          name='priorityId'
          label='Priority'
          hasEmptyOption={true}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          selectedValue={3}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <Field
          component={Select}
          options={props.milestones}
          id='milestoneId'
          name='milestoneId'
          label='Milestone'
          hasEmptyOption={true}
          fullWidth
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          component={Input}
          id='estimate'
          name='estimate'
          label='Estimate'
          type='number'
          fullWidth
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Field
          component={Select}
          options={props.assignedTo}
          id='assignedToId'
          name='assignedToId'
          label='Assigned To'
          hasEmptyOption={true}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default CreateUpdateTicket;
