import * as actionTypes from './actionTypes';
import { useGet } from '../utilities/webServices';

// Workspace Setter and Clearer
const setAndClearWorkspace = (values) => {
  return {
    type: actionTypes.GLOBAL_WORKSPACE_SETTER_AND_CLEARER,
    payload: {
      currentWorkspace: values.currentWorkspace,
      currentWorkspaceUser: values.currentWorkspaceUser,
      currentWorkspaceRole: values.currentWorkspaceRole,
    },
  };
};

// Message Setter and Clearer
const setAndClearMessage = (values) => {
  return {
    type: actionTypes.GLOBAL_MESSAGE_SETTER_AND_CLEARER,
    payload: {
      message: values.message,
      messageType: values.messageType,
    },
  };
};

// Loading Setter and Clearer
const setAndClearLoading = (values) => {
  return {
    type: actionTypes.GLOBAL_LOADING_SETTER_AND_CLEARER,
    payload: {
      loading: values.loading,
    },
  };
};

// Roles Fetcher
const fetchRoles = (values) => {
  return {
    type: actionTypes.GLOBAL_ROLES_FETCHER,
    payload: {
      roles: values,
    },
  };
};

// Priorities Fetcher
const fetchPriorities = (values) => {
  return {
    type: actionTypes.GLOBAL_PRIORITIES_FETCHER,
    payload: {
      priorities: values,
    },
  };
};

// Action Creators
// Workspace Setter
export const workspaceSetter = (values) => {
  return async (dispatch) => {
    let spacePrefData = {
      currentWorkspace: values.currentWorkspace,
      currentWorkspaceUser: values.currentWorkspaceUser,
      currentWorkspaceRole: values.currentWorkspaceRole,
    };
    localStorage.setItem('tmsSpacePref', JSON.stringify(spacePrefData));
    await dispatch(setAndClearWorkspace(values));
  };
};

// Workspace Clearer
export const workspaceClearer = () => {
  return async (dispatch) => {
    localStorage.removeItem('tmsSpacePref');
    await dispatch(
      setAndClearWorkspace({
        currentWorkspace: {},
        currentWorkspaceUser: {},
        currentWorkspaceRole: {},
      }),
    );
  };
};

// Message Setter & Clearer
export const messageSetterAndClearer = (values) => {
  return async (dispatch) => {
    await dispatch(setAndClearMessage(values));
    setTimeout(() => {
      dispatch(setAndClearMessage({ message: null, messageType: null }));
    }, 5000);
  };
};

// Roles Fetcher
export const rolesFetcher = (values) => {
  return async (dispatch) => {
    dispatch(setAndClearLoading({ loading: true }));
    try {
      const response = await useGet('/rest/roles', values.token);
      if (response && response._embedded.roles) {
        dispatch(fetchRoles(response._embedded.roles));
      } else {
        dispatch(setAndClearLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setAndClearLoading({ loading: false }));
    }
  };
};

// Priorities Fetcher
export const prioritiesFetcher = (values) => {
  return async (dispatch) => {
    dispatch(setAndClearLoading({ loading: true }));
    try {
      const response = await useGet('/rest/priorities', values.token);
      if (response && response._embedded.priorities) {
        dispatch(fetchPriorities(response._embedded.priorities));
      } else {
        dispatch(setAndClearLoading({ loading: false }));
      }
    } catch (error) {
      dispatch(setAndClearLoading({ loading: false }));
    }
  };
};
