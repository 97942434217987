import React from 'react';
import { Field } from 'react-final-form';

// Components
import Input from '../../components/Input/Input';

const CreateUpdateStatus = () => {
  return (
    <>
      <Field
        component={Input}
        id='name'
        name='name'
        label='Name'
        type='text'
        margin='normal'
        fullWidth
        required
        autoFocus
      />
      <Field
        component={Input}
        id='orderValue'
        name='orderValue'
        label='Order'
        type='text'
        margin='normal'
        fullWidth
        required
      />
    </>
  );
};

export default CreateUpdateStatus;
