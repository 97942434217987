import React from 'react';
import { connect } from 'react-redux';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Logo
import logo from '../../assets/images/logo.png';

// Styles
import useStyles from './errorStyles';

const Error = (props) => {
  var classes = useStyles();

  // Global
  const { isAuthenticated } = props;

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotype}>
        <img src={logo} alt='logo' className={classes.logotypeImage} />
      </div>
      <Paper classes={{ root: classes.paperRoot }}>
        <Typography
          variant='h1'
          color='primary'
          className={classnames(classes.textRow, classes.errorCode)}
        >
          404
        </Typography>
        <Typography variant='h5' color='primary' className={classes.textRow}>
          Oops! Looks like the page you're looking for no longer exists
        </Typography>
        <Typography
          variant='h6'
          color='primary'
          colorbrightness='secondary'
          className={classnames(classes.textRow, classes.safetyText)}
        >
          But we're here to bring you back to safety
        </Typography>
        <Button
          variant='contained'
          color='primary'
          component={Link}
          to={isAuthenticated ? '/dashboard' : '/'}
          size='large'
          className={classes.backButton}
        >
          Back to Home
        </Button>
      </Paper>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.user.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Error);
