import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import classnames from 'classnames';

// Styles
var useStyles = makeStyles((theme) => ({
  dotBase: {
    width: 8,
    height: 8,
    backgroundColor: theme.palette.text.hint,
    borderRadius: '50%',
    transition: theme.transitions.create('background-color'),
  },
  dotSmall: {
    width: 5,
    height: 5,
  },
  dotLarge: {
    width: 11,
    height: 11,
  },
}));

const Dot = (props) => {
  var classes = useStyles();
  var theme = useTheme();

  return (
    <div
      className={classnames(classes.dotBase, {
        [classes.dotLarge]: props.size === 'large',
        [classes.dotSmall]: props.size === 'small',
      })}
      style={{
        backgroundColor:
          props.color &&
          theme.palette[props.color] &&
          theme.palette[props.color].main,
      }}
    />
  );
};

export default Dot;
