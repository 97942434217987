import * as actionTypes from './actionTypes';
import { usePost, useGet } from '../utilities/webServices';
import { userLogout } from './userAction';
import { messageSetterAndClearer } from './globalAction';

// Read
const teamReadRequest = () => {
  return {
    type: actionTypes.TEAM_READ_REQUEST,
  };
};

const teamReadSuccess = (values) => {
  return {
    type: actionTypes.TEAM_READ_SUCCESS,
    payload: {
      teams: values.data,
    },
  };
};

const teamReadFailure = () => {
  return {
    type: actionTypes.TEAM_READ_FAILURE,
  };
};

// Invite User
const teamInviteUserRequest = () => {
  return {
    type: actionTypes.TEAM_INVITE_USER_REQUEST,
  };
};

const teamInviteUserSuccess = () => {
  return {
    type: actionTypes.TEAM_INVITE_USER_SUCCESS,
  };
};

const teamInviteUserFailure = () => {
  return {
    type: actionTypes.TEAM_INVITE_USER_FAILURE,
  };
};

// Edit User Role
const teamEditUserRoleRequest = () => {
  return {
    type: actionTypes.TEAM_EDIT_USER_ROLE_REQUEST,
  };
};

const teamEditUserRoleSuccess = (values) => {
  return {
    type: actionTypes.TEAM_EDIT_USER_ROLE_SUCCESS,
    payload: {
      user: values.data,
    },
  };
};

const teamEditUserRoleFailure = () => {
  return {
    type: actionTypes.TEAM_EDIT_USER_ROLE_FAILURE,
  };
};

// Delete User
const teamDeleteUserRequest = () => {
  return {
    type: actionTypes.TEAM_USER_DELETE_REQUEST,
  };
};

const teamDeleteUserSuccess = (values) => {
  return {
    type: actionTypes.TEAM_USER_DELETE_SUCCESS,
    payload: {
      userId: values.id,
    },
  };
};

const teamDeleteUserFailure = () => {
  return {
    type: actionTypes.TEAM_USER_DELETE_FAILURE,
  };
};

// Action Creators
// Read
export const teamRead = (values) => {
  return async (dispatch) => {
    dispatch(teamReadRequest());
    try {
      const response = await useGet(
        `/workspace/${values.workspaceId}/list-all-users`,
        values.token,
      );
      if (response && response.data) {
        dispatch(teamReadSuccess(response));
      } else if ((response.status = 401)) {
        dispatch(userLogout());
      } else {
        dispatch(teamReadFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(teamReadFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Invite User
export const teamInviteUser = (values) => {
  return async (dispatch) => {
    dispatch(teamInviteUserRequest());
    try {
      const response = await usePost('/workspace/invite', values);
      if (response.data && response.data.id) {
        dispatch(teamInviteUserSuccess());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(teamInviteUserFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(teamInviteUserFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Edit User Role
export const teamEditUserRole = (values) => {
  return async (dispatch) => {
    dispatch(teamEditUserRoleRequest());
    try {
      const response = await usePost('/workspace/update-user-role', values);
      if (response.data && response.data.id) {
        dispatch(teamEditUserRoleSuccess(response));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(teamEditUserRoleFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(teamEditUserRoleFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};

// Delete User
export const teamDeleteUser = (values) => {
  return async (dispatch) => {
    dispatch(teamDeleteUserRequest());
    try {
      const response = await usePost('/workspace/remove-user', values);
      if (response.data) {
        dispatch(teamDeleteUserSuccess(values.formData));
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 1,
          }),
        );
      } else {
        dispatch(teamDeleteUserFailure());
        dispatch(
          messageSetterAndClearer({
            message: response.message,
            messageType: 2,
          }),
        );
      }
    } catch (error) {
      dispatch(teamDeleteUserFailure());
      dispatch(
        messageSetterAndClearer({
          message: error.message,
          messageType: 2,
        }),
      );
    }
  };
};
