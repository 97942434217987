// Global
export const GLOBAL_WORKSPACE_SETTER_AND_CLEARER =
  'GLOBAL_WORKSPACE_SETTER_AND_CLEARER';
export const GLOBAL_MESSAGE_SETTER_AND_CLEARER =
  'GLOBAL_MESSAGE_SETTER_AND_CLEARER';
export const GLOBAL_LOADING_SETTER_AND_CLEARER =
  'GLOBAL_LOADING_SETTER_AND_CLEARER';
export const GLOBAL_ROLES_FETCHER = 'GLOBAL_ROLES_FETCHER';
export const GLOBAL_PRIORITIES_FETCHER = 'GLOBAL_PRIORITIES_FETCHER';

// User Module
export const USER_REGISTRATION_REQUEST = 'USER_REGISTRATION_REQUEST';
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS';
export const USER_REGISTRATION_FAILURE = 'USER_REGISTRATION_FAILURE';
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_ERROR_MESSAGE_TIMEOUT = 'USER_ERROR_MESSAGE_TIMEOUT';
export const USER_LOGIN_TOKEN_VERIFICATION_REQUEST =
  'USER_LOGIN_TOKEN_VERIFICATION_REQUEST';
export const USER_LOGIN_TOKEN_VERIFICATION_SUCCESS =
  'USER_LOGIN_TOKEN_VERIFICATION_SUCCESS';
export const USER_LOGIN_TOKEN_VERIFICATION_FAILURE =
  'USER_LOGIN_TOKEN_VERIFICATION_FAILURE';

// Workspace Module
export const WORKSPACE_CREATE_UPDATE_REQUEST =
  'WORKSPACE_CREATE_UPDATE_REQUEST';
export const WORKSPACE_CREATE_UPDATE_SUCCESS =
  'WORKSPACE_CREATE_UPDATE_SUCCESS';
export const WORKSPACE_CREATE_UPDATE_FAILURE =
  'WORKSPACE_CREATE_UPDATE_FAILURE';
export const WORKSPACE_READ_REQUEST = 'WORKSPACE_READ_REQUEST';
export const WORKSPACE_READ_SUCCESS = 'WORKSPACE_READ_SUCCESS';
export const WORKSPACE_READ_FAILURE = 'WORKSPACE_READ_FAILURE';
export const WORKSPACE_INVITATION_READ_REQUEST =
  'WORKSPACE_INVITATION_READ_REQUEST';
export const WORKSPACE_INVITATION_READ_SUCCESS =
  'WORKSPACE_INVITATION_READ_SUCCESS';
export const WORKSPACE_INVITATION_READ_FAILURE =
  'WORKSPACE_INVITATION_READ_FAILURE';
export const WORKSPACE_INVITATION_ACCEPT_REQUEST =
  'WORKSPACE_INVITATION_ACCEPT_REQUEST';
export const WORKSPACE_INVITATION_ACCEPT_SUCCESS =
  'WORKSPACE_INVITATION_ACCEPT_SUCCESS';
export const WORKSPACE_INVITATION_ACCEPT_FAILURE =
  'WORKSPACE_INVITATION_ACCEPT_FAILURE';
export const WORKSPACE_SENT_INVITATION_READ_REQUEST =
  'WORKSPACE_SENT_INVITATION_READ_REQUEST';
export const WORKSPACE_SENT_INVITATION_READ_SUCCESS =
  'WORKSPACE_SENT_INVITATION_READ_SUCCESS';
export const WORKSPACE_SENT_INVITATION_READ_FAILURE =
  'WORKSPACE_SENT_INVITATION_READ_FAILURE';
export const WORKSPACE_DELETE_REQUEST = 'WORKSPACE_DELETE_REQUEST';
export const WORKSPACE_DELETE_SUCCESS = 'WORKSPACE_DELETE_SUCCESS';
export const WORKSPACE_DELETE_FAILURE = 'WORKSPACE_DELETE_FAILURE';

// Status Module
export const STATUS_CREATE_UPDATE_REQUEST = 'STATUS_CREATE_UPDATE_REQUEST';
export const STATUS_CREATE_UPDATE_SUCCESS = 'STATUS_CREATE_UPDATE_SUCCESS';
export const STATUS_CREATE_UPDATE_FAILURE = 'STATUS_CREATE_UPDATE_FAILURE';
export const STATUS_READ_REQUEST = 'STATUS_READ_REQUEST';
export const STATUS_READ_SUCCESS = 'STATUS_READ_SUCCESS';
export const STATUS_READ_FAILURE = 'STATUS_READ_FAILURE';
export const STATUS_DELETE_REQUEST = 'STATUS_DELETE_REQUEST';
export const STATUS_DELETE_SUCCESS = 'STATUS_DELETE_SUCCESS';
export const STATUS_DELETE_FAILURE = 'STATUS_DELETE_FAILURE';

// Ticket Module
export const TICKET_CREATE_UPDATE_REQUEST = 'TICKET_CREATE_UPDATE_REQUEST';
export const TICKET_CREATE_UPDATE_SUCCESS = 'TICKET_CREATE_UPDATE_SUCCESS';
export const TICKET_CREATE_UPDATE_FAILURE = 'TICKET_CREATE_UPDATE_FAILURE';
export const TICKET_READ_REQUEST = 'TICKET_READ_REQUEST';
export const TICKET_READ_SUCCESS = 'TICKET_READ_SUCCESS';
export const TICKET_READ_FAILURE = 'TICKET_READ_FAILURE';
export const TICKET_READ_BY_ID_REQUEST = 'TICKET_READ_BY_ID_REQUEST';
export const TICKET_READ_BY_ID_SUCCESS = 'TICKET_READ_BY_ID_SUCCESS';
export const TICKET_READ_BY_ID_FAILURE = 'TICKET_READ_BY_ID_FAILURE';
export const TICKET_DELETE_REQUEST = 'TICKET_DELETE_REQUEST';
export const TICKET_DELETE_SUCCESS = 'TICKET_DELETE_SUCCESS';
export const TICKET_DELETE_FAILURE = 'TICKET_DELETE_FAILURE';
export const TICKET_COMMENT_CREATE_UPDATE_REQUEST =
  'TICKET_COMMENT_CREATE_UPDATE_REQUEST';
export const TICKET_COMMENT_CREATE_UPDATE_SUCCESS =
  'TICKET_COMMENT_CREATE_UPDATE_SUCCESS';
export const TICKET_COMMENT_CREATE_UPDATE_FAILURE =
  'TICKET_COMMENT_CREATE_UPDATE_FAILURE';
export const TICKET_COMMENT_DELETE_REQUEST = 'TICKET_COMMENT_DELETE_REQUEST';
export const TICKET_COMMENT_DELETE_SUCCESS = 'TICKET_COMMENT_DELETE_SUCCESS';
export const TICKET_COMMENT_DELETE_FAILURE = 'TICKET_COMMENT_DELETE_FAILURE';
export const TICKET_IMAGES_SETTER_AND_CLEARER =
  'TICKET_IMAGES_SETTER_AND_CLEARER';
export const TICKET_SEARCH_REQUEST = 'TICKET_SEARCH_REQUEST';
export const TICKET_SEARCH_SUCCESS = 'TICKET_SEARCH_SUCCESS';
export const TICKET_SEARCH_FAILURE = 'TICKET_SEARCH_FAILURE';

// Team Module
export const TEAM_READ_REQUEST = 'TEAM_READ_REQUEST';
export const TEAM_READ_SUCCESS = 'TEAM_READ_SUCCESS';
export const TEAM_READ_FAILURE = 'TEAM_READ_FAILURE';
export const TEAM_INVITE_USER_REQUEST = 'TEAM_INVITE_USER_REQUEST';
export const TEAM_INVITE_USER_SUCCESS = 'TEAM_INVITE_USER_SUCCESS';
export const TEAM_INVITE_USER_FAILURE = 'TEAM_INVITE_USER_FAILURE';
export const TEAM_EDIT_USER_ROLE_REQUEST = 'TEAM_EDIT_USER_ROLE_REQUEST';
export const TEAM_EDIT_USER_ROLE_SUCCESS = 'TEAM_EDIT_USER_ROLE_SUCCESS';
export const TEAM_EDIT_USER_ROLE_FAILURE = 'TEAM_EDIT_USER_ROLE_FAILURE';
export const TEAM_USER_DELETE_REQUEST = 'TEAM_USER_DELETE_REQUEST';
export const TEAM_USER_DELETE_SUCCESS = 'TEAM_USER_DELETE_SUCCESS';
export const TEAM_USER_DELETE_FAILURE = 'TEAM_USER_DELETE_FAILURE';

// Milestone Module
export const MILESTONE_CREATE_UPDATE_REQUEST =
  'MILESTONE_CREATE_UPDATE_REQUEST';
export const MILESTONE_CREATE_UPDATE_SUCCESS =
  'MILESTONE_CREATE_UPDATE_SUCCESS';
export const MILESTONE_CREATE_UPDATE_FAILURE =
  'MILESTONE_CREATE_UPDATE_FAILURE';
export const MILESTONE_READ_REQUEST = 'MILESTONE_READ_REQUEST';
export const MILESTONE_READ_SUCCESS = 'MILESTONE_READ_SUCCESS';
export const MILESTONE_READ_FAILURE = 'MILESTONE_READ_FAILURE';
export const MILESTONE_DELETE_REQUEST = 'MILESTONE_DELETE_REQUEST';
export const MILESTONE_DELETE_SUCCESS = 'MILESTONE_DELETE_SUCCESS';
export const MILESTONE_DELETE_FAILURE = 'MILESTONE_DELETE_FAILURE';
