import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utilities/helpers';

// Check localStorage for tmsAuth
let auth_store = localStorage.getItem('tmsAuth')
  ? JSON.parse(localStorage.getItem('tmsAuth'))
  : '';

const initialState = {
  isAuthenticated: auth_store && auth_store.token ? true : false,
  token: auth_store && auth_store.token ? auth_store.token : null,
  user: auth_store && auth_store.user ? auth_store.user : null,
  message: null,
  messageType: null, // 0 is Success, 1 is Error
  loading: false,
};

// Registration
const userRegisterationRequest = (state, action) => {
  return updateObject(state, {
    message: null,
    messageType: null,
    loading: true,
  });
};

const userRegisterationSuccess = (state, action) => {
  return updateObject(state, {
    message: action.payload.message,
    messageType: 0,
    loading: false,
  });
};

const userRegisterationFailure = (state, action) => {
  return updateObject(state, {
    message: action.payload.message,
    messageType: 1,
    loading: false,
  });
};

// Login
const userLoginRequest = (state, action) => {
  return updateObject(state, {
    message: null,
    messageType: null,
    loading: true,
  });
};

const userLoginSuccess = (state, action) => {
  return updateObject(state, {
    isAuthenticated: true,
    token: action.payload.token,
    user: action.payload.user,
    loading: false,
  });
};

const userLoginFailure = (state, action) => {
  return updateObject(state, {
    message: action.payload.message,
    messageType: 1,
    loading: false,
  });
};

// Logout
const userLogout = (state, action) => {
  return updateObject(state, {
    isAuthenticated: false,
    token: null,
    user: null,
    message: 'You have been logged out. Please login to continue.',
  });
};

// Timeout Message
const userMessageTimeout = (state, action) => {
  return updateObject(state, { message: null, messageType: null });
};

// Verify Token
const userTokenVerificationRequest = (state, action) => {
  return updateObject(state, {
    message: null,
    messageType: null,
    loading: true,
  });
};

const userTokenVerificationSuccess = (state, action) => {
  return updateObject(state, {
    message: action.payload.message,
    messageType: 0,
    loading: false,
  });
};

const userTokenVerificationFailure = (state, action) => {
  return updateObject(state, {
    message: action.payload.message,
    messageType: 1,
    loading: false,
  });
};

// Reducer
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    // Registration
    case actionTypes.USER_REGISTRATION_REQUEST:
      return userRegisterationRequest(state, action);
    case actionTypes.USER_REGISTRATION_SUCCESS:
      return userRegisterationSuccess(state, action);
    case actionTypes.USER_REGISTRATION_FAILURE:
      return userRegisterationFailure(state, action);
    // Login
    case actionTypes.USER_LOGIN_REQUEST:
      return userLoginRequest(state, action);
    case actionTypes.USER_LOGIN_SUCCESS:
      return userLoginSuccess(state, action);
    case actionTypes.USER_LOGIN_FAILURE:
      return userLoginFailure(state, action);
    // Logout
    case actionTypes.USER_LOGOUT:
      return userLogout(state, action);
    // Timeout Message
    case actionTypes.USER_ERROR_MESSAGE_TIMEOUT:
      return userMessageTimeout(state, action);
    // Verify Token
    case actionTypes.USER_LOGIN_TOKEN_VERIFICATION_REQUEST:
      return userTokenVerificationRequest(state, action);
    case actionTypes.USER_LOGIN_TOKEN_VERIFICATION_SUCCESS:
      return userTokenVerificationSuccess(state, action);
    case actionTypes.USER_LOGIN_TOKEN_VERIFICATION_FAILURE:
      return userTokenVerificationFailure(state, action);
    default:
      return state;
  }
};

export default userReducer;
