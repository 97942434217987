import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, IconButton, ButtonGroup } from '@material-ui/core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';

// Actions
import { ticketCreateUpdate, ticketSearchClearer } from '../../store/actions';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Dialog from '../../components/Dialog/Dialog';

// Pages
import CreateUpdateTicket from './CreateUpdateTicket';

const ReadTicket = (props) => {
  // On Load
  useEffect(() => {
    document.title = `Tickets | ${process.env.REACT_APP_NAME}`;
  }, []);

  // Local
  const [openCreateTicket, setOpenCreateTicket] = React.useState(false);

  const handleOpenCreateTicket = () => {
    setOpenCreateTicket(true);
  };

  const handleCloseCreateTicket = () => {
    setOpenCreateTicket(false);
  };

  const handleSubmitCreateTicket = (values) => {
    const modifiedValues = {
      ...values,
      priorityId: values.priorityId ? values.priorityId : 3,
      statusId: values.statusId ? values.statusId : props.statuses[0].id,
      workspaceId: parseInt(props.currentWorkspace.id),
    };
    props
      .onSubmitTicket({
        url: '/ticket/create',
        values: {
          token: props.token,
          formData: modifiedValues,
        },
      })
      .then(() => {
        handleCloseCreateTicket();
      });
  };

  const handleClickViewTicketDetail = (values) => {
    props.history.push({
      pathname: `/workspace/${values.workspace.id}/ticket/${values.id}`,
      state: { ticket: values },
    });
  };

  // Map Users
  let assignedTo = {};
  if (props.teams) {
    assignedTo = props.teams.map((team) => {
      return { id: team.user.id, name: team.user.userName };
    });
  }

  // Rows
  let tableStructure = [];
  let defaultOrSearch = props.tickets;
  if (props.search.length) {
    defaultOrSearch = props.search;
  }
  if (props.tickets) {
    tableStructure = defaultOrSearch.map((ticket) => {
      return [
        ticket.displayId,
        ticket.title,
        ticket.status ? ticket.status.name : 'Not Specified',
        ticket.milestone ? ticket.milestone.name : 'Not Specified',
        {
          ...ticket,
        },
      ];
    });
  }

  // Columns
  const columns = [
    '#',
    'Name',
    'Status',
    'Milestone',
    {
      name: 'Actions',
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                onClick={() => handleClickViewTicketDetail(value)}
                color='primary'
                aria-label='view-ticket-detail'
                component='span'
                size='small'
              >
                <VisibilityIcon fontSize='small' />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  // Config
  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
  };

  return (
    <>
      <PageTitle
        title={'Tickets'}
        button={
          props.currentWorkspaceRole.id !== 4 && (
            <ButtonGroup>
              <Button
                variant='outlined'
                size='medium'
                color='secondary'
                onClick={props.onClickClearSearch}
              >
                Clear Search
              </Button>
              <Button
                variant='outlined'
                size='medium'
                color='secondary'
                onClick={handleOpenCreateTicket}
              >
                Add ticket
              </Button>
            </ButtonGroup>
          )
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title='All Tickets'
            data={tableStructure}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={openCreateTicket}
        handleClose={handleCloseCreateTicket}
        title='Create Ticket'
        handleSubmit={handleSubmitCreateTicket}
        isLoading={props.isLoading}
      >
        <CreateUpdateTicket
          statuses={props.statuses}
          assignedTo={assignedTo}
          token={props.token}
          priorities={props.priorities}
          milestones={props.milestones}
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    tickets: state.ticket.tickets,
    search: state.ticket.search,
    currentWorkspace: state.global.currentWorkspace,
    currentWorkspaceRole: state.global.currentWorkspaceRole,
    statuses: state.status.statuses,
    teams: state.team.teams,
    priorities: state.global.priorities,
    milestones: state.milestone.milestones,
    isLoading: state.ticket.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitTicket: (values) => dispatch(ticketCreateUpdate(values)),
    onClickClearSearch: () => dispatch(ticketSearchClearer()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadTicket);
