import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Button, IconButton } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';

// Actions
import { statusCreateUpdate, statusDelete } from '../../store/actions';

// Components
import PageTitle from '../../components/PageTitle/PageTitle';
import Dialog from '../../components/Dialog/Dialog';

// Pages
import CreateUpdateStatus from './CreateUpdateStatus';

const ReadStatus = (props) => {
  // On Load
  useEffect(() => {
    document.title = `Statuses | ${process.env.REACT_APP_NAME}`;
  }, []);

  // Local
  const [openCreateUpdateStatus, setOpenCreateUpdateStatus] = React.useState(
    false,
  );
  const [valueForm, setValueForm] = React.useState(null);

  const handleOpenCreateStatus = () => {
    setOpenCreateUpdateStatus(true);
  };

  const handleOpenEditStatus = (values) => {
    setValueForm(values);
    setOpenCreateUpdateStatus(true);
  };

  const handleCloseCreateOrEditStatus = () => {
    setValueForm(null);
    setOpenCreateUpdateStatus(false);
  };

  const handleSubmitCreateUpdateStatus = (values) => {
    const modifiedValues = {
      ...values,
      workspaceId: parseInt(props.currentWorkspace.id),
    };
    if (values.id) {
      props
        .onSubmitStatus({
          url: '/status/update',
          values: {
            token: props.token,
            formData: modifiedValues,
          },
        })
        .then(() => {
          handleCloseCreateOrEditStatus();
        });
    } else {
      props
        .onSubmitStatus({
          url: '/status/create',
          values: {
            token: props.token,
            formData: modifiedValues,
          },
        })
        .then(() => {
          handleCloseCreateOrEditStatus();
        });
    }
  };

  const handleSubmitDeleteStatus = (id, name) => {
    const modifiedValues = {
      token: props.token,
      formData: { id: id },
    };
    const result = window.confirm(
      `Are you sure you want to delete status ${name}?`,
    );
    if (result) {
      props.onSubmitDeleteStatus(modifiedValues);
    }
  };

  // Rows
  let tableStructure = [];
  if (props.statuses) {
    tableStructure = props.statuses.map((status) => {
      return [
        status.name,
        status.orderValue,
        props.currentWorkspaceRole.id !== 3 && {
          id: status.id,
          name: status.name,
          orderValue: status.orderValue,
        },
      ];
    });
  }

  // Columns
  const columns = ['Name', 'Order'];
  if (props.currentWorkspaceRole.id !== 3)
    columns.push({
      name: 'Actions',
      options: {
        customBodyRender: (value) => {
          return (
            <>
              <IconButton
                onClick={() => handleOpenEditStatus(value)}
                color='primary'
                aria-label='create-edit-status'
                component='span'
                size='small'
              >
                <EditIcon fontSize='small' />
              </IconButton>
              <IconButton
                onClick={() => handleSubmitDeleteStatus(value.id, value.name)}
                color='primary'
                aria-label='delete-milestone'
                component='span'
                size='small'
              >
                <DeleteIcon fontSize='small' />
              </IconButton>
            </>
          );
        },
      },
    });

  // Config
  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
  };

  return (
    <>
      <PageTitle
        title={'Statuses'}
        button={
          props.currentWorkspaceRole.id !== 3 && (
            <Button
              variant='outlined'
              size='medium'
              color='secondary'
              onClick={handleOpenCreateStatus}
            >
              Add Status
            </Button>
          )
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <MUIDataTable
            title='All Statuses'
            data={tableStructure}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={openCreateUpdateStatus}
        handleClose={handleCloseCreateOrEditStatus}
        title={`${valueForm ? 'Edit' : 'Create'} Status`}
        handleSubmit={handleSubmitCreateUpdateStatus}
        isLoading={props.isLoading}
        initialValues={valueForm}
      >
        <CreateUpdateStatus />
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    statuses: state.status.statuses,
    isLoading: state.status.loading,
    currentWorkspace: state.global.currentWorkspace,
    currentWorkspaceRole: state.global.currentWorkspaceRole,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitStatus: (values) => dispatch(statusCreateUpdate(values)),
    onSubmitDeleteStatus: (values) => dispatch(statusDelete(values)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReadStatus);
