import { combineReducers } from 'redux';

// Child Reducers
import globalReducer from './globalReducer';
import userReducer from './userReducer';
import workspaceReducer from './workspaceReducer';
import statusReducer from './statusReducer';
import ticketReducer from './ticketReducer';
import teamReducer from './teamReducer';
import milestoneReducer from './milestoneReducer';

// Parent Reducer
const rootReducer = combineReducers({
  global: globalReducer,
  user: userReducer,
  workspace: workspaceReducer,
  status: statusReducer,
  ticket: ticketReducer,
  team: teamReducer,
  milestone: milestoneReducer,
});

export default rootReducer;
